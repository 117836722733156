import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { HttpsvcService } from 'src/app/services/httpsvc.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [ MessageService ]
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  
  isLog: boolean;
  loading = false;

  changepwdForm: FormGroup;

  subscription: Subscription;
  _token: string;

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private formBuilder: FormBuilder, 
    private httpsvc: HttpsvcService,
    private messageService: MessageService,
  ) { 

    //this._token = sessionStorage.getItem('_token');

    if(localStorage.getItem("rememberme") == "true") {
      this._token = localStorage.getItem('_token');
    }
    else if(localStorage.getItem("rememberme") == "false") {
      this._token = sessionStorage.getItem('_token');
    }
    else {
      this._token = ""; 
    }

  }

  checkexpiredToken(): boolean {
    let bln: boolean = true;

    if(this._token != "") {
      this.subscription = this.httpsvc.getTNY(this._token).subscribe(resp => {
        bln = true;
      }, err => {
        bln = false;
        console.log(bln);
        //console.log(err);
      });
    }
    else {
      bln = false;
    }

    console.log(bln);
    return bln;
  }

  ngOnInit(): void {
    // this.isLog = this.sharedService.getGlobalInput();

    // if(!this.isLog) {
    //   this.router.navigate(['/']).then(() => {
    //     window.location.reload();
    //   });
    // }
    let blnsuccess: boolean;
    blnsuccess = this.checkexpiredToken();

    if(blnsuccess) {
      const remLS = localStorage.getItem("rememberme");
      if(remLS == "false") {
        if(sessionStorage.getItem("isLogin") == "false") {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }
        else if(sessionStorage.getItem("isLogin") == "true") {
          this.initForm();
        }
        else {
          this.sharedService.remLocalStorage();
          this.sharedService.remSessionStorage();
          this.router.navigate(['/']);
        }
      }
      else if(remLS == "true") {
        this.initForm();
      }
      else {
        this.sharedService.remLocalStorage();
        this.sharedService.remSessionStorage();
        this.router.navigate(['/']);
      }
    }
    else {
      console.log(blnsuccess);
      this.sharedService.remLocalStorage();
      this.sharedService.remSessionStorage();
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    }

    
  }

  get f() { return this.changepwdForm.controls; }

  initForm() {
    this.changepwdForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      role: ['0', Validators.required]
    }, {
      validator: this.mustMatch('password', 'confirmPassword') // Custom validator
    });
  }

  mustMatch(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passControl = formGroup.controls[password];
      const confirmPassControl = formGroup.controls[confirmPassword];

      if (confirmPassControl.errors && !confirmPassControl.errors.mustMatch) {
        // Return if another validator has already found an error on confirmPassword
        return;
      }

      // Set error on confirmPassword if validation fails
      if (passControl.value !== confirmPassControl.value) {
        confirmPassControl.setErrors({ mustMatch: true });
      } else {
        confirmPassControl.setErrors(null);
      }
    };
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
    
  } 

  onSubmit() {
    this.loading = true;

    if (this.changepwdForm.valid) {
      var input = {
        "password" : this.changepwdForm.controls['password'].value,
      }

      this.subscription = this.httpsvc.changePwd(this._token, input, sessionStorage.getItem("_usrid")).subscribe(() => {
        this.showMsgSvc('Password successfully changed. You will be redirectly logout after 3 seconds.', 'success','Success');
        this.loading = false;

        setTimeout(() => {
          this.sharedService.remLocalStorage();
          this.sharedService.remSessionStorage();
          this.router.navigate(['/']).then(()=> {
            window.location.reload();
          });
        }, 3000);
        
      }, err => {
        this.loading = false;
        if(err.status == 401) {
          this.sharedService.remLocalStorage();
          this.sharedService.remSessionStorage();
          this.router.navigate(['/']).then(()=> {
            window.location.reload();
          });
        }
        console.log(err);
      })
    }
    else {
      this.loading = false;
      this.changepwdForm.markAllAsTouched();
    }
  }

  showMsgSvc(msg: string, severe: string, summ: string) {
    this.messageService.add({key: 'tc', severity: severe, summary: summ, detail: msg}); 
  }

}
