import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Subscription } from 'rxjs';
import { HttpsvcService } from 'src/app/services/httpsvc.service';
import { SharedService } from 'src/app/services/shared.service';

import HC_more from 'highcharts/highcharts-more';  // Import highcharts-more for gauge charts
import HC_solidGauge from 'highcharts/modules/solid-gauge';  // For solid gauge
import { Router } from '@angular/router';

import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import autoTable from 'jspdf-autotable';
// import Modal from 'bootstrap/js/dist/modal.js';
//import { Modal } from 'bootstrap';
//import { Dialog } from 'primeng/dialog';
// import { Table } from 'primeng/table';

import { CurrencyPipe } from '@angular/common';


// Initialize Highcharts modules
HC_more(Highcharts);
HC_solidGauge(Highcharts);

// declare var ResizeObserver: any;

// declare var bootstrap: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [CurrencyPipe]
})
export class DashboardComponent implements OnInit, OnDestroy {

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  chartOptions1: Highcharts.Options;
  chartOptions2: Highcharts.Options;
  chartOptions3: Highcharts.Options;
  chartOptions4: Highcharts.Options;
  chartOptions5: Highcharts.Options;
  chartOptions6: Highcharts.Options;
  chartConstructor = "chart";

  subscription: Subscription;

  isLoadingc1 = true; 
  isLoadingc2 = true;
  isLoadingc3 = true;
  isLoadingc4 = true;
  isLoadingc5 = true;
  isLoadingc6 = true;
  isLoadingc7 = true;
  
  isLoadingMSRtbl = true;

  mytable: any;

  totalnetsales = 0;
  overallgrowth = 0;
  salesachievement = 0;
  sssggrowth = 0; 

  isLog: boolean;

  _totalNSCurrent = 0;
  _totalNSPrevious = 0;
  _totalGSCurrent = 0;
  _totalGSPrevious = 0;
  _diffNS = 0;
  _diffGS = 0;

  _dtYesterday = '';
  _dtToday = '';
  _todaynet = 0;
  _todaygross = 0;
  _yestnet = 0;
  _yestgross = 0;
  _cntBranchyest = 0;
  _cntBranchtoday = 0;
  _actBranchyest = 0;
  _actBranchtoday = 0;
  _ytdgrowth = 0;


  _name: string;
  _token: string;


  displayModal: boolean = false;
  displayModal1: boolean = false;
  displayModal2: boolean = false;
  displayModal3: boolean = false;

  modalData: { category: string, value: number } = { category: '', value: 0 };

  _dtDSDetl: any[] = [];
  _dtMSRBranchDailyNSDetl: any[] = [];
  _flterDSMonth: any[] = [];
  
  _sFltrMonth: string;
  _sFltrMonth1: string;
  _sFltrMonth2: string;
  _sFltrMonth3: string;
  _sBranchName: string;

  _DSTotlNSytd = 0;
  _DSTotlNSyrago = 0;
  _DSTotlGrowth = 0;

  _sssgdtl_nsytd = 0;
  _sssgdtl_nsyrago = 0;
  _sssgdtl_nsgrowth = 0;

  _dtFlterSSSGDetl:any[] = [];

  _adsDetl_currtotsalesmonth = 0;
  _adsDetl_curradsmonth = 0;
  _adsDetl_salesquota = 0;
  _adsDetl_salesachi = 0;
  _adsDetl_prevadsmonth = 0;
  _adsDetl_growthprevyr = 0;
  _adsDetl_ytdads = 0;
  _adsDetl_yragoytdads = 0;
  _adsDetl_ytdgrowth = 0;

  _tblCols_dsDetl: any[]; 
  _tblCols_sssgDetl: any[]; 
  _tblCols_msrDetl: any[];
  _exportCols_dsDetl: any[];
  _exportCols_sssgDetl: any[];

  _tblCols_brnchmodetl: any[];
  _export_brnchmodetl: any[];
  _dtMOBranchDetl: any[] = [];
  _dtFlterMOBranchDetl: any[] = [];
  frozenCols: any[];

  _dtFlterMSRTableDailyNS:any[] = [];

  selmsr: any[] = [];
  selsssg: any[] = [];
  selgrowth: any[] = [];

  private chartRef: Highcharts.Chart;

  sortOrder1: number;
  sortOrder2: number;
  sortOrder3: number;


  _tblmsr_totlnetsales: number = 0;
  _tblmsr_ads: number = 0;
  _tblmsr_quota: number = 0;
  _tblmsr_achi: number = 0;
  _tblmsr_adsprevyr: number = 0;
  _tblmsr_growthprevyr: number = 0;
  _tblmsr_ytdads: number = 0;
  _tblmsr_prevytdads: number = 0;
  _tblmsr_ytdgrowth: number = 0;

  _valrunAvgMSR: any[] = [];

  //@ViewChild('myDialog') myDialog: Dialog;
  // @ViewChild('dty') _dty: Table;

  lastUpdated: Date;
  strDate: string;
  bln: boolean = true;

  constructor(
    private httpsvc: HttpsvcService,
    private sharedService: SharedService,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private cdr: ChangeDetectorRef
    
  ) { 
    //this._name = this.router.getCurrentNavigation().extras.state.name;
    if(localStorage.getItem("rememberme") == "true") {
      this._token = localStorage.getItem('_token');
    }
    else if(localStorage.getItem("rememberme") == "false") {
      this._token = sessionStorage.getItem('_token');
    }
    else {
      this._token = "";
    }
    
    
  }
  

  onDialogHide(rpt: string) {
    // this.sortField = null; // Reset sort field
    switch(rpt) {
      case 'msr' :
        this.sortOrder1 = 0;
        break;
      case 'sssg' :
        this.sortOrder2 = 0;
        break;
      case 'growth' :
        this.sortOrder3 = 0;
        break;
    }
  }

  checkexpiredToken(): boolean {
    let bln: boolean = true;

    if(this._token != "") {
      this.subscription = this.httpsvc.getTNY(this._token).subscribe(resp => {
        bln = true;
      }, err => {
        bln = false;
        this.sharedService.remLocalStorage();
        this.sharedService.remSessionStorage();
      });
    }
    else {
      this.subscription = this.httpsvc.getTNY(this._token).subscribe(resp => {
        bln = true;
      }, err => {
        bln = false;
        this.sharedService.remLocalStorage();
        this.sharedService.remSessionStorage();
      });
    }

    return bln;
  }

  ngOnInit(): void {
    // this.isLog = this.sharedService.getGlobalInput();
    // if(!this.sharedService.getGlobalInput()) {
    //   this.router.navigate(['/']).then(() => {
    //     window.location.reload();
    //   });
    // }

    let blnsuccess: boolean = this.checkexpiredToken();

    if(blnsuccess) {
      const remLS = localStorage.getItem("rememberme");

      if(remLS == "false" ) {
        if(sessionStorage.getItem("isLogin") == "false") {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }
        else if(sessionStorage.getItem("isLogin") == "true") {
          this.updateLastUpdatedTime();
          setInterval(() => {
            this.updateLastUpdatedTime();
            window.location.reload();
          }, 3600000); 
          // 3600000 1 hour in milliseconds

          this.getInfoTNY();
          this.chartDailySales();
          this.chartSalesAchi();
          this.chartMonthlyGrowth();
          this.chartSSSG();

          this.subscription = this.httpsvc.getBranchSalesDetl(this._token).subscribe(resp => {
            this._dtMOBranchDetl = resp.body;
          }, err => {
            console.log(err);
          });

          this.frozenCols = [
            { field: 's_brncname', header: 'BRANCH NAME' }
          ];

          this.subscription = this.httpsvc.getMSRBranchDlyNS(this._token).subscribe(resp => { 
            this._dtMSRBranchDailyNSDetl = resp.body;
          }, err => {
            console.log(err);
          });
        }
        else {
          this.sharedService.remLocalStorage();
          this.sharedService.remSessionStorage();
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }
      }
      else if (remLS == "true") {
        this.updateLastUpdatedTime();
        setInterval(() => {
          this.updateLastUpdatedTime();
          window.location.reload();
        }, 3600000); 
        // 3600000 1 hour in milliseconds

        this.getInfoTNY();
        this.chartDailySales();
        this.chartSalesAchi();
        this.chartMonthlyGrowth();
        this.chartSSSG();

        this.subscription = this.httpsvc.getBranchSalesDetl(this._token).subscribe(resp => {
          this._dtMOBranchDetl = resp.body;
        }, err => {
          console.log(err);
        });

        this.frozenCols = [
          { field: 's_brncname', header: 'BRANCH NAME' }
        ];

        this.subscription = this.httpsvc.getMSRBranchDlyNS(this._token).subscribe(resp => { 
          this._dtMSRBranchDailyNSDetl = resp.body;
        }, err => {
          console.log(err);
        });
      }
      else {
        this.sharedService.remLocalStorage();
        this.sharedService.remSessionStorage();
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      }
    }
    else {
      this.sharedService.remLocalStorage();
      this.sharedService.remSessionStorage();
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }

    

    // this.updateLastUpdatedTime();
    // setInterval(() => {
    //   this.updateLastUpdatedTime();
    //   window.location.reload();
    // }, 3600000); 
    // // 3600000 1 hour in milliseconds

    // this.getInfoTNY();
    // this.chartDailySales();
    // this.chartSalesAchi();
    // this.chartMonthlyGrowth();
    // this.chartSSSG();

    // this.subscription = this.httpsvc.getBranchSalesDetl(this._token).subscribe(resp => {
    //   this._dtMOBranchDetl = resp.body;
    // }, err => {
    //   console.log(err);
    // });

    // this.frozenCols = [
    //   { field: 's_brncname', header: 'BRANCH NAME' }
    // ];

    // this.subscription = this.httpsvc.getMSRBranchDlyNS(this._token).subscribe(resp => { 
    //   this._dtMSRBranchDailyNSDetl = resp.body;
    // }, err => {
    //   console.log(err);
    // });
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
      
  }

  getInfoTNY() {
    this.subscription = this.httpsvc.getTNY(this._token).subscribe(resp => {
      let dt: any;
      dt = resp.body;

      this._dtToday = dt[1].dtformat;
      this._todaynet = dt[1].netsales;
      this._todaygross = dt[1].grossale;
      this._dtYesterday = dt[0].dtformat;
      this._yestnet = dt[0].netsales;
      this._yestgross = dt[0].grossale;

      this._cntBranchtoday = dt[1].cntbranch;
      this._actBranchtoday = dt[1].noofbranch;

      this._cntBranchyest = dt[0].cntbranch;
      this._actBranchyest = dt[0].noofbranch;


      this._dtToday = this.getFullMonthName(this._dtToday);
      this._dtYesterday = this.getFullMonthName(this._dtYesterday);
      
    }, err=> {
      console.log(err);
      if(err.status == 401) {
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      }
      
    })
  }

  showtblDSDetl(monthName: string) {
    const modal = document.getElementById('modalOVG');
    if(modal) {
      modal.style.display = "block";
    }
    // const myModal = new Modal(document.getElementById('modalOVG')!);
    // myModal.show();

    // this.displayModal = true;

    this._sFltrMonth = this.getFullMonthName(monthName + ".");

    this._DSTotlNSytd = 0;
    this._DSTotlNSyrago = 0;
    this._DSTotlGrowth = 0;

    let ytdsales = 0;
    let yragosales = 0;

    this._tblCols_dsDetl = [
      { field: 'bname', header: 'BRANCH NAME' },
      { field: 'netsalescurr', header: 'NET SALES (curyr)' },
      { field: 'netsalesprev', header: 'NET SALES (yrago)' },
      { field: 'growth', header: 'GROWTH' },
    ];

    this._exportCols_dsDetl = this._tblCols_dsDetl.map(col => ({title: col.header, dataKey: col.field}));

    this.subscription = this.httpsvc.getDSDetl(this._token).subscribe(resp => {
      this._dtDSDetl = resp.body;
      
      this._flterDSMonth = this._dtDSDetl.filter(mo => mo.moname === monthName);

      for(let i = 0; i < this._flterDSMonth.length; i++) {
        ytdsales = this._flterDSMonth[i].netsalescurr;
        yragosales = this._flterDSMonth[i].netsalesprev;

        this._DSTotlNSytd = this._DSTotlNSytd + ytdsales;
        this._DSTotlNSyrago = this._DSTotlNSyrago + yragosales;

      }

      this._DSTotlGrowth = (this._DSTotlNSytd - this._DSTotlNSyrago) / this._DSTotlNSyrago * 100;

      this.chartDSDetl(monthName);

    }, err => {
      console.log(err);
    });

  }

  updateLastUpdatedTime() {
    this.lastUpdated = new Date();
    this.strDate = this.lastUpdated.toLocaleString();
  }

  showMSRBranchDailyNS(monthName: string, bname: string) {

    const modal = document.getElementById('modalADSDetl');
    if(modal) {
      modal.style.display = "block";
    }

    // const myModal = new Modal(document.getElementById('modalADSDetl')!);
    // myModal.show();
    // this.cdr.detectChanges(); 
    
    let m = monthName.substring(0,3); 
    this._sFltrMonth3 = monthName;
    this._sBranchName = bname;


    this._tblCols_msrDetl = [
      // { field: 'moname', header: 'MONTH' },
      // { field: 'dday', header: 'DATE' },
      // { field: 'dtname', header: 'DAY' },
      { field: 'dtdate', header: 'DATE' },
      { field: 'custcnt', header: 'COUNT' },
      { field: 'netsales', header: 'NET SALES' },
    ];

    this._dtFlterMSRTableDailyNS = this._dtMSRBranchDailyNSDetl.filter(dt => dt.moname === m && dt.bname === bname);
    //console.log(this._dtFlterMSRTableDailyNS);
      
    let dtvx: any[] = [];

    dtvx = this._dtMOBranchDetl.filter(dt => dt.moname === m && dt.s_brncname === bname);
    //dtvx = this._dtFlterMSRTableDailyNS.filter(dt => dt.moname === m && dt.s_brncname === bname);
    //console.log(dtvx);

    this._tblmsr_totlnetsales = Number(dtvx[0].ytd_netsales);
    this._tblmsr_ads = Number(dtvx[0].ytdads);
    this._tblmsr_quota = Number(dtvx[0].quotasale);
    this._tblmsr_achi = Number(dtvx[0].salesachi);
    this._tblmsr_adsprevyr = Number(dtvx[0].yragoads);
    this._tblmsr_growthprevyr = Number(dtvx[0].growthads);
    this._tblmsr_ytdads = Number(dtvx[0].running_ytads);
    this._tblmsr_prevytdads = Number(dtvx[0].running_yragoads);
    this._tblmsr_ytdgrowth = Number(dtvx[0].yrago_growthads);

    this.isLoadingMSRtbl = false;

    // this.subscription = this.httpsvc.getMSRBranchDlyNS(this._token).subscribe(resp => {
      
    //   this._dtMSRBranchDailyNSDetl = resp.body;
    //   //console.log(resp);

    //   this._dtFlterMSRTableDailyNS = this._dtMSRBranchDailyNSDetl.filter(dt => dt.moname === m && dt.bname === bname);
      
    //   let dtvx: any[] = [];

    //   dtvx = this._dtMOBranchDetl.filter(dt => dt.moname === m && dt.s_brncname === bname);
    //   //dtvx = this._dtFlterMSRTableDailyNS.filter(dt => dt.moname === m && dt.s_brncname === bname);
    //   //console.log(dtvx);

    //   this._tblmsr_totlnetsales = Number(dtvx[0].ytd_netsales);
    //   this._tblmsr_ads = Number(dtvx[0].ytdads);
    //   this._tblmsr_quota = Number(dtvx[0].quotasale);
    //   this._tblmsr_achi = Number(dtvx[0].salesachi);
    //   this._tblmsr_adsprevyr = Number(dtvx[0].yragoads);
    //   this._tblmsr_growthprevyr = Number(dtvx[0].growthads);
    //   this._tblmsr_ytdads = Number(dtvx[0].running_ytads);
    //   this._tblmsr_prevytdads = Number(dtvx[0].running_yragoads);
    //   this._tblmsr_ytdgrowth = Number(dtvx[0].yrago_growthads);

    //   this.isLoadingMSRtbl = false;

    // }, err => {
    //   this.isLoadingMSRtbl = false;
    //   console.log(err);
    // });

  }

  exportPdf(moname: string, fname: string, dtname: string) {
    const doc = new jsPDF();
    doc.setFont("Arial");
    switch(dtname) {
      case 'overallgrowth' :
        autoTable(doc, {
          head: [['BRANCH NAME', 'NETSALES (curyr)', 'NETSALES (yrago)', 'GROWTH']],
          body: this._flterDSMonth.map(item => [
            item.bname, 
            this.currencyPipe.transform(item.netsalescurr, 'Php ', 'symbol', '1.2-2'),
            this.currencyPipe.transform(item.netsalesprev, 'Php ', 'symbol', '1.2-2'),
            Number(item.growth).toFixed(2)
          ]),
        });
      
        doc.save(fname + ' ('+ this._sFltrMonth+').pdf');
        break;

      case 'sssgrowth' :
        autoTable(doc, {
          head: [['BRANCH NAME', 'NETSALES (curyr)', 'NETSALES (yrago)', 'GROWTH']],
          body: this._dtFlterSSSGDetl.map(item => [
            item.bname, 
            this.currencyPipe.transform(item.netsalescurr, 'Php ', 'symbol', '1.2-2'),
            this.currencyPipe.transform(item.netsalesprev, 'Php ', 'symbol', '1.2-2'),
            Number(item.growth).toFixed(2)
          ]),
        });
      
        doc.save(fname + ' ('+ this._sFltrMonth1 +').pdf');
        break;

      case 'msr' :
        const docx = new jsPDF("landscape");
        autoTable(docx, {
          head: [[
            'BRANCH NAME',
            'TOTAL NET SALES',
            'ADS',
            'QUOTA',
            'SALES ACHIEVEMENT %',
            'ADS PREV YR',
            'GROWTH vs. PREV YR',
            'YTD ADS',
            'PREV YTD ADS',
            'YTD GROWTH'
          ]],
          body: this._dtFlterMOBranchDetl.map(item => [
            item.s_brncname, 
            this.currencyPipe.transform(item.ytd_netsales, 'Php ', 'symbol', '1.2-2'),
            this.currencyPipe.transform(item.ytdads, 'Php ', 'symbol', '1.2-2'),
            this.currencyPipe.transform(item.quotasale, 'Php ', 'symbol', '1.2-2'),
            Number(item.salesachi).toFixed(2),
            this.currencyPipe.transform(item.yragoads, 'Php ', 'symbol', '1.2-2'),
            Number(item.growthads).toFixed(2),
            this.currencyPipe.transform(item.running_ytads, 'Php ', 'symbol', '1.2-2'),
            this.currencyPipe.transform(item.running_yragoads, 'Php ', 'symbol', '1.2-2'),
            Number(item.yrago_growthads).toFixed(2),
          ]),

        });
      
        docx.save(fname + ' ('+ this._sFltrMonth2 +').pdf');
        break;

    }
    
  }

  exportExcel(moname: string, fname: string, tblname: string) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.getDSNS(tblname));
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer,fname + ' ('+ moname +')');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  }

  getDSNS(tblname: string) {
    let sales = [];
    switch(tblname) {
      case 'overallgrowth':
        for(let x of this._flterDSMonth) {
          sales.push(
            {
              'BRANCH NAME': x.bname,
              'NET SALES (curyr)': x.netsalescurr,
              'NET SALES (yrago)': x.netsalesprev,
              'GROWTH': x.growth
            }
          );
        }

        break;

      case 'sssgrowth' :
        for(let x of this._dtFlterSSSGDetl) {
          sales.push(
            {
              'BRANCH NAME': x.bname,
              'NET SALES (curyr)': x.netsalescurr,
              'NET SALES (yrago)': x.netsalesprev,
              'GROWTH': x.growth
            }
          );
        }
        break;

      case 'msr' : 
        for(let x of this._dtFlterMOBranchDetl) {
          sales.push(
            {
              'BRANCH NAME': x.s_brncname,
              'TOTAL NET SALES': x.ytd_netsales,
              'ADS': x.ytdads,
              'QUOTA': x.quotasale,
              'SALES ACHIEVEMENT %': x.salesachi,
              'ADS PREV YR': x.yragoads,
              'GROWTH vs. PREV YR': x.growthads,
              'YTD ADS': x.running_ytads,
              'PREV YTD ADS': x.running_yragoads,
              'YTD GROWTH': x.yrago_growthads
            }
          )
        }
    }
    
    return sales;
  }

  showADSDetl(monthname: string) {

    const modal = document.getElementById('modalADS');
    if(modal) {
      modal.style.display = "block";
    }
    
    // const myModal = new Modal(document.getElementById('modalADS')!);
    // myModal.show();
    // this.cdr.detectChanges(); 

    this.subscription = this.httpsvc.getADSDetail(this._token).subscribe(resp => {
      // this.displayModal2 = true;
      //this._sFltrMonth2 = monthname;
      this._sFltrMonth2 = this.getFullMonthName(monthname + ".");

      let dt: any;
      dt = resp.body;

      let dtf: any[] = [];

      dtf = dt.filter(mo => mo.moname === monthname);
      
      let cnt = 0;
      let ytdads = 0;
      let ytdyragoads = 0

      for(let x = 0; x < dt.length; x++) {
        if(monthname != dt[x].moname) {
          cnt = cnt + 1;
          ytdads = ytdads + dt[x].currTotalADS;
          ytdyragoads = ytdyragoads + dt[x].ytdprevTotalADS;

        } else {
          cnt = cnt + 1;
          ytdads = ytdads + dt[x].currTotalADS;
          ytdyragoads = ytdyragoads + dt[x].ytdprevTotalADS;

          break;
        }
      }

      this._adsDetl_ytdads = ytdads / cnt;
      this._adsDetl_yragoytdads = ytdyragoads / cnt;
      this._adsDetl_ytdgrowth = (this._adsDetl_ytdads - this._adsDetl_yragoytdads) / this._adsDetl_yragoytdads * 100;

      for(let i = 0; i < dtf.length; i++) {
        this._adsDetl_currtotsalesmonth = dtf[i].currNetSales;  
        this._adsDetl_curradsmonth = dtf[i].currTotalADS;
        this._adsDetl_salesquota = dtf[i].quotaSale;
        this._adsDetl_salesachi = dtf[i].salesachi;
        this._adsDetl_prevadsmonth = dtf[i].prevTotalADS;
        this._adsDetl_growthprevyr = (this._adsDetl_curradsmonth - this._adsDetl_prevadsmonth) / this._adsDetl_prevadsmonth * 100;
      }

      this.chartADSDetlNS(monthname);
      
    }, err => {
      console.log(err);
    });

    // Data Table Monthly Branch Sales Detail

    this._tblCols_brnchmodetl = [
      { field: 's_brncname', header: 'BRANCH NAME' },
      { field: 'ytd_netsales', header: 'TOTAL NET SALES' },
      { field: 'ytdads', header: 'ADS' },
      { field: 'quotasale', header: 'QUOTA' },
      { field: 'salesachi', header: 'ACHIEVEMENT %' },
      { field: 'yragoads', header: 'ADS PREV YR' },
      { field: 'growthads', header: 'GROWTH vs. PREV YR' },
      { field: 'running_ytads', header: 'YTD ADS' },
      { field: 'running_yragoads', header: 'PREV YTD ADS' },
      { field: 'yrago_growthads', header: 'YTD GROWTH %' },
    ]

    // this.frozenCols = [
    //   { field: 's_brncname', header: 'BRANCH NAME' }
    // ];

    this._dtFlterMOBranchDetl = this._dtMOBranchDetl.filter(x => x.moname === monthname);

    
    
  }

  calculateRunningAvg(dt: any, rpt: string) {
    let total = 0;

    // Calculate the running average
    switch(rpt) {
      case 'msr' :
        this._valrunAvgMSR = dt.map((item, index) => {
          total += item;
          return total / (index + 1); // Running average
        });
      break;
    }
  }

  chartADSDetlNS(monthname: string) {
    this.subscription = this.httpsvc.getDSDetlChart(this._token).subscribe(resp => {
      this.isLoadingc7 = true;

      let dt: any;
      let dtflter: any[] = [];
      let dataVal: any[] = [];
      let dataCat: any[] = [];
      // let runavg: any[] = [];
      // let total = 0;

      dt = resp.body;

      dtflter = dt.filter(mo => mo.moname === monthname);
      
      for(let x = 0; x < dtflter.length; x++) {
        dataCat.push(dtflter[x].dtday);
        dataVal.push(Number(dtflter[x].netsalescurr));
      }

      // this.calculateRunningAvg(dataVal,'msr');


      this.chartOptions6 = {
        chart: {
          type: 'spline'
        },
        title: {
          text: '',
          style: {
            display: 'none'
          }
        },
        legend: { 
          enabled:false 
        },
        xAxis: {
          categories: dataCat,
          gridLineWidth: 0
        },
        yAxis: {
          title: {
            text: ''
          },
          style: {
            display: 'none'
          },
          gridLineWidth: 0,
          // plotLines:[{
          //   value:this._adsDetl_curradsmonth,
          //   color: '#d07575',
          //   width:2,
          //   zIndex:4,
          //   label:{text:'ADS'}
          // }]
        },
        series: [
          {
            type: 'spline',
            name: 'Net Sales',
            data: dataVal,
            // color: '#b4d21c',
          },
          // {
          //   type: 'spline',
          //   name: 'AVG',
          //   data: this._valrunAvgMSR,
          //   // color: '#b4d21c',
          // },
        ],
        tooltip: {
          formatter: function () {
            return `<b>${this.series.name}</b><br/>${this.x}: ₱ ${Highcharts.numberFormat(this.y, 2, '.', ',')}`;
            
          }
        },
        // responsive: {  
        //   rules: [{  
        //     condition: {  
        //       maxWidth: 500  
        //     },  
        //     chartOptions: {  
        //       legend: {  
        //         enabled: false  
        //       }  
        //     }  
        //   }]  
        // },
        
      }
      
      this.isLoadingc7 = false;


    }, err => {
      console.log(err);
    });

  }

  chartSSSGDetl(moname: string) {
    this.subscription = this.httpsvc.getSSSGGrowthDetlCh(this._token).subscribe(resp => {
      this.isLoadingc6 = true;

      let dt: any;
      let dtflter: any[] = [];
      let dataVal: any[] = [];
      let dataCat: any[] = [];

      dt = resp.body;

      dtflter = dt.filter(mo => mo.moname === moname);
      
      for(let x = 0; x < dtflter.length; x++) {
        dataCat.push(dtflter[x].dtday);
        dataVal.push(Number(dtflter[x].netsalescurr));
      }

      this.chartOptions5 = {
        chart: {
          type: 'spline'
        },
        title: {
          text: '',
          style: {
            display: 'none'
          }
        },
        legend: { 
          enabled:false 
        },
        xAxis: {
          categories: dataCat,
          gridLineWidth: 0
        },
        yAxis: {
          title: {
            text: ''
          },
          style: {
            display: 'none'
          },
          gridLineWidth: 0
        },
        series: [
          {
            type: 'spline',
            name: 'Net Sales',
            data: dataVal,
            color: '#d535ed',
          },
        ],
        tooltip: {
          formatter: function () {
            return `<b>${this.series.name}</b><br/>${this.x}: ₱ ${Highcharts.numberFormat(this.y, 2, '.', ',')}`;
            
          }
        },
        responsive: {  
          rules: [{  
            condition: {  
              maxWidth: 500  
            },  
            chartOptions: {  
              legend: {  
                enabled: false  
              }  
            }  
          }]  
        }
      }
      
      this.isLoadingc6 = false;

    }, err => {
      console.log(err);
    })
  }

  chartDSDetl(monthame: string) {
    
    this.subscription = this.httpsvc.getDSDetlChart(this._token).subscribe(resp => {
      this.isLoadingc5 = true;

      let dt: any;
      let dtflter: any[] = [];
      let dataVal: any[] = [];
      let dataCat: any[] = [];
      let runavg: any[] = [];
      let total = 0;

      dt = resp.body;

      dtflter = dt.filter(mo => mo.moname === monthame);
      
      for(let x = 0; x < dtflter.length; x++) {
        dataCat.push(dtflter[x].dtday);
        dataVal.push(Number(dtflter[x].netsalescurr));
      }

      runavg = dataVal.map((item, index) => {
        total += item.value;
        return total / (index + 1); // Running average
      });

      console.log(runavg);

      this.chartOptions4 = {
        chart: {
          type: 'spline'
        },
        title: {
          text: '',
          style: {
            display: 'none'
          }
        },
        legend: { 
          enabled:false 
        },
        xAxis: {
          categories: dataCat,
          gridLineWidth: 0
        },
        yAxis: {
          title: {
            text: ''
          },
          style: {
            display: 'none'
          },
          gridLineWidth: 0
        },
        series: [
          {
            type: 'spline',
            name: 'Net Sales',
            data: dataVal,
            color: '#b4d21c',
          },
        ],
        tooltip: {
          formatter: function () {
            return `<b>${this.series.name}</b><br/>${this.x}: ₱ ${Highcharts.numberFormat(this.y, 2, '.', ',')}`;
            
          }
        },
        responsive: {  
          rules: [{  
            condition: {  
              maxWidth: 500  
            },  
            chartOptions: {  
              legend: {  
                enabled: false  
              }  
            }  
          }]  
        }
      }
      
      this.isLoadingc5 = false;


    }, err => {
      console.log(err);
    })
  }

  chartDailySales() {
    this.subscription = this.httpsvc.getDailySales(this._token).subscribe(resp => {
      let dt: any;
      let dataValCurr: any[] = [];
      let dataValPrev: any[] = [];
      let dataSeries: any[] = [];
      //let dataValNSCurr: any[] = [];
      //let dataVal

      dt = resp.body

      //console.log(dt);

      let sales = 0;
      let nsPrev = 0;
      let nsCurr = 0;

      let cntr = 0;

      for(let i = 0;i < dt.length; i++ ) {
        cntr = cntr + 1;
        sales = Number(dt[i].currTotalADS);
        nsCurr = Number(dt[i].currNetSales);
        nsPrev = Number(dt[i].prevNetSales);

        dataValCurr.push(Number(dt[i].currTotalADS));
        dataValPrev.push(Number(dt[i].prevTotalADS));
        dataSeries.push(dt[i].moname);

        this.totalnetsales = this.totalnetsales + sales;
        this._totalNSCurrent = this._totalNSCurrent + nsCurr;

      
        if(nsCurr > 0) {
          this._totalNSPrevious = this._totalNSPrevious + nsPrev;
        }
        
      }

      this.totalnetsales = this.totalnetsales / cntr;

      this._ytdgrowth = ((this._totalNSCurrent / this._totalNSPrevious) / this._totalNSPrevious) * 100;

      this.chartOptions = {
        chart: {
          type: 'column'
        },
        title: {
          text: '',
          style: {
            display: 'none'
          }
        },
        legend: { 
          enabled:false 
        },
        xAxis: {
          categories: dataSeries,
          gridLineWidth: 0
        },
        yAxis: {
          title: {
            text: ''
          },
          style: {
            display: 'none'
          },
          gridLineWidth: 0
        },
        series: [
          {
            type: 'column',
            name: 'ADS',
            data: dataValCurr,
            cursor: 'hand',
            events: {
              click: (event: any) => {
                const xLabel = event.point.category;
                // this.showDialog(xLabel, event.point.y);
                this.showADSDetl(xLabel);
              }
            }
          },
          {
            type: 'line',
            name: 'YR-AGO ADS',
            data: dataValPrev
          }
        ],
        tooltip: {
          shared: true,
          useHTML: true,
          formatter: function () {
            const s1 = this.points[0].y;
            const s2 = this.points[1].y;
            const xdiff = s1 - s2;

            //return `<b>${this.series.name}</b><br/>${this.x}: ₱ ${Highcharts.numberFormat(this.y, 2, '.', ',')}`;
            return `<b>${this.x}</b><br/>
                    <span><i class="bi bi-circle-fill" style="color: ${this.points[0].color}"></i> ${this.points[0].series.name}: ₱ ${Highcharts.numberFormat(s1, 2, '.', ',')}</span><br>
                    <span><i class="bi bi-circle-fill" style="color: ${this.points[1].color}"></i> ${this.points[1].series.name}: ₱ ${Highcharts.numberFormat(s2, 2, '.', ',')}</span><br>
                    <span>DIFF: ₱ ${Highcharts.numberFormat(xdiff, 2, '.', ',')}</span><br><i>Click to see details.</i>`
          }
        }
      }
      
      this.isLoadingc1 = false;

    }, err => {
      console.log(err);
    })
  }

  chartMonthlyGrowth() {
    this.subscription = this.httpsvc.getMonthlyGrowth(this._token).subscribe(resp => {
      let dtx: any;
      let dataSeries: any[] = [];
      let dataVal: any[] = [];

      dtx = resp.body

      // console.log(dt);
      let _prevADS = 0;
      let _currADS = 0;

      for(let i = 0; i < dtx.length; i++) {
        let xcurrADS = 0;
        xcurrADS = Number(dtx[i].currADS);

        dataSeries.push(dtx[i].moname);
        dataVal.push(Number(dtx[i].growth));

        if(xcurrADS > 0) {
          _currADS = _currADS + xcurrADS
          _prevADS = _prevADS + Number(dtx[i].prevADS);
        }
        
      }

      this.overallgrowth = (_currADS - _prevADS) / _prevADS * 100;

      this.chartOptions1 = {
        title: {
          text: '',
          style: {
            display: 'none'
          }
        },
        legend: { 
          enabled:false 
        },
        xAxis: {
          categories: dataSeries
        },
        yAxis: {
          title: {
            text: ''
          },
          style: {
            display: 'none'
          },
          gridLineWidth: 0
        },
        series: [
          {
            type: 'column',
            name: 'PERCENTAGE',
            data: dataVal,
            color: '#b4d21c',
            cursor: 'hand',
            events: {
              click: (event: any) => {
                const xLabel = event.point.category;
                // this.showDialog(xLabel, event.point.y);
                this.showtblDSDetl(xLabel);
              }
            }
          },
          
        ],
        tooltip: {
          formatter: function () {
            return `<b>${this.series.name}</b><br/>${this.x}: ${Highcharts.numberFormat(this.y, 2, '.', ',')}% <br><br><i>Click to see details.</i>`;
          }
        }
      };
      
      this.isLoadingc2 = false;

    }, err => {
      console.log(err);
    })
  }

  chartSSSG() {
    this.subscription = this.httpsvc.getSSSG(this._token).subscribe(resp => {
      let dtx: any;
      let dataSeries: any[] = [];
      let dataVal: any[] = [];

      dtx = resp.body

      // console.log(dt);
      let _prevADS = 0;
      let _currADS = 0;

      for(let i = 0; i < dtx.length; i++) {
        let xcurrADS = 0;
        xcurrADS = Number(dtx[i].currADS);

        dataSeries.push(dtx[i].moname);
        dataVal.push(Number(dtx[i].growth));

        if(xcurrADS > 0) {
          _currADS = _currADS + xcurrADS
          _prevADS = _prevADS + Number(dtx[i].prevADS);
        }
        
      }

      this.sssggrowth = (_currADS - _prevADS) / _prevADS * 100;

      this.chartOptions3 = {
        title: {
          text: '',
          style: {
            display: 'none'
          }
        },
        legend: { 
          enabled:false 
        },
        xAxis: {
          categories: dataSeries
        },
        yAxis: {
          title: {
            text: ''
          },
          style: {
            display: 'none'
          },
          gridLineWidth: 0
        },
        series: [
          {
            type: 'column',
            name: 'PERCENTAGE',
            data: dataVal,
            color: '#d535ed',
            cursor: 'hand',
            events: {
              click: (event: any) => {
                const xLabel = event.point.category;
                // this.showDialog(xLabel, event.point.y);
                this.showSSSGDetl(xLabel);
              }
            }
          },
          
        ],
        tooltip: {
          formatter: function () {
            return `<b>${this.series.name}</b><br/>${this.x}: ${Highcharts.numberFormat(this.y, 2, '.', ',')}% <br><i>Click to see details</i>`;
          }
        }
      };
      this.isLoadingc4 = false;
    }, err => {
      console.log(err);
    })
  }

  showSSSGDetl(moname: string) {

    const modal = document.getElementById('modalSSSG');
    if(modal) {
      modal.style.display = "block";
    }

    // const myModal = new Modal(document.getElementById('modalSSSG')!);
    // myModal.show();
    // this.cdr.detectChanges(); 

    // this.displayModal1 = true;
    this._sFltrMonth1 = this.getFullMonthName(moname + ".");

    this._sssgdtl_nsytd = 0;
    this._sssgdtl_nsyrago = 0;
    this._sssgdtl_nsgrowth = 0;

    let sytd = 0;
    let syrago = 0;

    let dt: any[] = [];
    let dtf: any[] = [];

    this.subscription = this.httpsvc.getSSSGGrowthDetl(this._token).subscribe(resp => {
      dt = resp.body;
      dtf = dt.filter(mo => mo.moname === moname);
      this._dtFlterSSSGDetl = dtf;

      this._tblCols_sssgDetl = [
        { field: 'bname', header: 'BRANCH NAME' },
        { field: 'netsalescurr', header: 'NET SALES (curyr)' },
        { field: 'netsalesprev', header: 'NET SALES (yrago)' },
        { field: 'growth', header: 'GROWTH' },
      ];
  
      this._exportCols_sssgDetl = this._tblCols_sssgDetl.map(col => ({title: col.header, dataKey: col.field}));
      
      for(let x = 0; x < dtf.length; x++) {
        sytd = dtf[x].netsalescurr;
        syrago = dtf[x].netsalesprev;

        this._sssgdtl_nsytd = this._sssgdtl_nsytd + sytd;
        this._sssgdtl_nsyrago = this._sssgdtl_nsyrago + syrago;
      }

      this._sssgdtl_nsgrowth = (this._sssgdtl_nsytd - this._sssgdtl_nsyrago) / this._sssgdtl_nsyrago * 100;

      this.chartSSSGDetl(moname);

    }, err => { 
      console.log(err);
    })
  }

  chartSalesAchi() {
    this.subscription = this.httpsvc.getSalesAchi(this._token).subscribe(resp => {
      let dt: any;
      let dataVal: any[] = [];

      dt = resp.body

      // console.log(dt);
      this.salesachievement = Number(dt[1].achipercent);
      dataVal.push(Number(dt[1].achipercent));
      
      this.chartOptions2 = {
        chart: {
          type: 'solidgauge',
        },
        title: {
          text: '',
          style: {
            display: 'none'
          }
        },
        pane: {
          center: ['50%', '85%'],
          size: '150%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor:
              Highcharts.defaultOptions.legend?.backgroundColor || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc',
          },
        },
        tooltip: {
          enabled: false,
        },
        yAxis: {
          min: 0,
          max: 100,
          stops: [
            [0.1, '#DF5353'], // Red
            [0.5, '#DDDF0D'], // Yellow
            [0.9, '#55BF3B'], // Green '#55BF3B'
          ],
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 5,
          title: {
            text: 'Achievement',
            style: {
              display: 'none'
            }
          },
          labels: {
            y: 20,
          },
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: -20,
              borderWidth: 0,
              useHTML: true,
              format: '<div style="text-align:center"><span style="font-size:40px">{y}%</span><br/>' +
                      '<span style="font-size:12px;opacity:0.4"></span></div>',
            },
          },
        },
        series: [
          {
            name: 'Percentage',
            type: 'solidgauge',
            data: dataVal,
            dataLabels: {
              enabled: true,
              format: '<div style="text-align:center"><span style="font-size:40px; color:#7d7c7c">{point.y}%</span></div>',
            },
          },
        ],
        // Make chart responsive using breakpoints
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 600,  // Adjust for mobile devices
              },
              chartOptions: {
                pane: {
                  size: '100%', // Shrink the gauge for smaller screens
                },
                yAxis: {
                  title: {
                    text: '',
                  },
                  labels: {
                    y: 10,
                  },
                },
                plotOptions: {
                  solidgauge: {
                    dataLabels: {
                      format:
                        '<div style="text-align:center"><span style="font-size:15px">{y}%</span><br/>' +
                        '<span style="font-size:10px;opacity:0.4"></span></div>',
                    },
                  },
                },
              },
            },
          ],
        },
      }

      this.isLoadingc3 = false;

    }, err => {
      //this.isLoadingc3 = false;
      console.log(err);

    });
    
    
  }

  getFullMonthName(dt: string) {
    let dtx = dt.substring(0,4);
    let dty = dt.substring(4,13);

    // console.log(dtx +'-' + dty);
    switch(dtx) {
      case 'JAN.' : return 'JANUARY' + dty;
      case 'FEB.' : return 'FEBRUARY' + dty;
      case 'MAR.' : return 'MARCH' + dty;
      case 'APR.' : return 'APRIL' + dty;
      case 'MAY.' : return 'MAY' + dty;
      case 'JUN.' : return 'JUNE' + dty;
      case 'JUL.' : return 'JULY' + dty;
      case 'AUG.' : return 'AUGUST' + dty;
      case 'SEP.' : return 'SEPTEMBER' + dty;
      case 'OCT.' : return 'OCTOBER' + dty;
      case 'NOV.' : return 'NOVEMBER' + dty;
      case 'DEC.' : return 'DECEMBER' + dty;
    }
  } 

  showDialog(category: string, value: number) {
    // this.modalData = { category, value };
    // this.displayModal = true;
  }

  closeModalADS(mdlname: string) {
    const modal = document.getElementById(mdlname);
    //const myModal = new Modal(document.getElementById(mdlname));
    
    switch(mdlname) {
      case 'modalADS' :
        if(modal) {
          modal.style.display = "none";
        }
        // myModal.hide();
        // this.cdr.detectChanges(); 
        break;
      case 'modalOVG' :
        if(modal) {
          modal.style.display = "none";
        }
        //myModal.hide();
        // this.cdr.detectChanges(); 
        break;
      case 'modalSSSG' :
        if(modal) {
          modal.style.display = "none";
        }
        // myModal.hide();
        // this.cdr.detectChanges(); 
        break;
      case 'modalADSDetl' :
        if(modal) {
          modal.style.display = "none";
        }
        // myModal.hide();
        // this.cdr.detectChanges(); 
        //this.isLoadingMSRtbl = true;
        break;
    }
    
  }



}
