<nav class="navbar navbar-expand-lg fixed-top bg-light">
    <div class="container-fluid">
        <a class="navbar-brand" [routerLink]="['/dashboard']" style="font-size: 25px; font-weight: 500; color: #676767"><img src="../assets/logo.jpg" height="50" >&nbsp;&nbsp;Sales Dashboard</a>
        <div style="padding: 5px; color: #468a2f; border-radius: 3px; font-weight: 500">Welcome, {{_uname}}!</div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                &nbsp;&nbsp;<a [routerLink]="['/branch-map']" class="btn btn-outline-success" title="Click to see branches on google map."><i class="bi bi-geo-alt-fill"></i> Branch Map</a>
            </ul>
            <div class="d-flex">
                <!-- <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"> -->
                <!-- <button class="btn btn-outline-success" type="submit">Logout</button> -->
                <ul class="navbar-nav">
                    <li class="nav-item dropdown" [ngClass]="{ 'active': isActive('/login')}" style="padding:3px">
                        <div class="button-group">
                            <div class="dropdown">
                                <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <i class="pi pi-user"></i>{{_uname}}
                                </button>
                                <ul class="dropdown-menu" >
                                    <li>
                                        <a class="dropdown-item" *ngIf="isRole" (click)="clickCreateuser()" role="button"><i class="bi bi-person-fill-add"></i> Create User</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" (click)="clickChangePwd()" role="button"><i class="bi bi-pencil-square"></i> Change Password</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </li>
                    <li class="nav-item" [ngClass]="{ 'active': isActive('/login')}" style="padding:3px">
                        <!-- <a *ngIf="isRole" class="btn btn-primary pad" (click)="clickCreateuser()" title="Click to create user."><i class="pi pi-user"></i>Create User</a>&nbsp;&nbsp; -->
                            <a class="btn btn-danger pad" (click)="logout()" title="Bye!"><i class="pi pi-sign-out"></i> Logout</a>
                    </li>
                </ul>
            </div>
          </div>
        

        <!-- <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div> -->
        <!--
        <div class="offcanvas-body">
            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">Home</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Link</a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown
                </a>
                <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
            </li>
            </ul>
            <form class="d-flex mt-3" role="search">
            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-outline-success" type="submit">Search</button>
            </form>
        </div>
        -->
        <!-- </div> -->
    </div>
</nav>
<!-- <div style="height: 100px;"></div> -->
