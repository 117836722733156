<!-- <p>create-user works!</p> -->
<app-header></app-header>
<div style="margin-top: 120px"></div>
<div class="container-fluid">
    <div class="row justify-content-center g-3">
        <div class="col-12 col-md-6 col-md-3">
            <div class="col-lg-12">
                <a class="btn btn-success" [routerLink]="['/dashboard']"><i class="bi bi-house-fill"></i> Dashboard</a>
            </div>
            <br>
            <div class="card">
                <div class="card-header">
                    <span style="font-size: 18px; font-weight: bold; color: #676767">CREATE USER</span>
                </div>
                <div class="card-content">
                    <div class="col-12" style="padding:20px">
                        <form [formGroup]="createuserForm" (ngSubmit)="onSubmit()">
                            <!-- Name -->
                            <div class="form-group">
                              <label for="name">Name</label>
                              <input type="text" id="name" class="form-control" formControlName="name" [ngClass]="{'is-invalid': f.name.touched && f.name.invalid}" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                              <div *ngIf="f.name.touched && f.name.invalid" class="invalid-feedback">
                                <div *ngIf="f.name.errors?.required">Name is required</div>
                              </div>
                            </div>
                            <br>
                            <!-- Email -->
                            <div class="form-group">
                              <label for="email">Email</label>
                              <input type="email" id="email" class="form-control" formControlName="email" [ngClass]="{'is-invalid': f.email.touched && f.email.invalid}" />
                              <div *ngIf="f.email.touched && f.email.invalid" class="invalid-feedback">
                                <div *ngIf="f.email.errors?.required">Email is required</div>
                                <div *ngIf="f.email.errors?.email">Invalid email format</div>
                              </div>
                            </div>
                            <br>  
                            <!-- Password -->
                            <div class="form-group">
                              <label for="password">Password</label>
                              <input type="password" id="password" class="form-control" formControlName="password" [ngClass]="{'is-invalid': f.password.touched && f.password.invalid}" />
                              <div *ngIf="f.password.touched && f.password.invalid" class="invalid-feedback">
                                <div *ngIf="f.password.errors?.required">Password is required</div>
                              </div>
                            </div>
                            <br>
                            <!-- Confirm Password -->
                            <div class="form-group">
                              <label for="confirmPassword">Confirm Password</label>
                              <input
                                type="password"
                                id="confirmPassword"
                                class="form-control"
                                formControlName="confirmPassword"
                                [ngClass]="{'is-invalid': f.confirmPassword.touched && f.confirmPassword.invalid}"
                              />
                              <div *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid" class="invalid-feedback">
                                <div *ngIf="f.confirmPassword.errors?.required">Confirm password is required</div>
                                <div *ngIf="f.confirmPassword.errors?.mustMatch">Passwords must match</div>
                              </div>
                            </div>
                            <br>
                            <!-- Role -->
                            <div class="form-group">
                                <label>Role</label><br><br>
                                <div class="form-check">
                                    <input type="radio" class="form-check-input" formControlName="role" value="0" id="userRole" />
                                    <label for="userRole" class="form-check-label">User</label>
                                  </div>
                                <div class="form-check">
                                  <input type="radio" class="form-check-input" formControlName="role" value="1" id="adminRole" />
                                  <label for="adminRole" class="form-check-label">Admin</label>
                                </div>
                                <!-- Error handling for role field -->
                                <div *ngIf="f.role.touched && f.role.invalid" class="invalid-feedback">
                                  <div *ngIf="f.role.errors?.required">Role is required</div>
                                </div>
                              </div>
                              <br>
                            <!-- Submit Button -->
                            <!-- <button type="submit" class="btn btn-primary">Create User</button> -->
                            <button class="btn btn-primary" [disabled]="loading" type="submit" style="font-size: 16px; font-weight: bold">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Create User
                              </button>
                          </form>
                          <p-toast position="center" key="tc"></p-toast>
                    </div>
                    
                      
                </div>
            </div>
        </div>
    </div>
    
</div>
<br>
