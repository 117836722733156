<!-- <p>login works!</p> -->
<style>
    .bg-login {
        background: linear-gradient(180deg, #fcfcfc 0%, #90d0a4 100%); 
        padding: 20px; 
        border-radius: 5px 5px 5px 5px;
    }

    .cont-margin {
        padding-top: 100px;
    }
    .bg-image {
        background: url('../../../assets/login-bg.jpg');
        background-size: cover;
    }
</style>

<div class="container-fluid cont-margin bg-image" style="height: 100%; width: 100%;">
    <div class="row text-center" style="margin-bottom: 40px; ">
        <div class="col-sm ">
            <img src="../../../assets/forBC.png" style="max-width: 100%; width: 400px;">
        </div>
    </div>
    <p-toast position="top-center" key="tc"></p-toast>
    <div class="row justify-content-center" style="margin: 20px">
        <div class="col-sm-12 col-md-4 bg-login" style="margin: 5px;">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label style="font-size:16px; font-weight: bold; margin-bottom: 3px;">USER NAME</label>
                    <div class="input-group">
                        <span class="input-group-text">
                            <i class="pi pi-user" style="font-size: 2em;"></i> <!-- Replace 'bi-person' with the desired Bootstrap icon class -->
                        </span>
                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" required/>
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">Username is required</div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="form-group">
                    <label style="font-size:16px; font-weight: bold; margin-bottom: 3px;">PASSWORD</label>
                    <div class="input-group">
                        <span class="input-group-text">
                            <i class="pi pi-lock" style="font-size: 2em;"></i> <!-- Replace 'bi-person' with the desired Bootstrap icon class -->
                        </span>
                        <input type="password" formControlName="password" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" required/>
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </div>
                </div> 
                <br>
                <div class="form-group form-check">
                    <input type="checkbox" formControlName="rememberme" class="form-check-input">
                    <label class="text-secondary">Remember Me</label>
                </div>
                <br>  
                <div style="float: right; right: 5px;">
                  <!-- <button type="submit" class="btn btn-primary">Login</button> -->
                  <button class="btn btn-success" [disabled]="loading" type="submit" style="font-weight: bold">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>LOGIN
                  </button>
                </div>
            </form>
        </div>
    </div>
</div>
