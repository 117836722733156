import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { HttpsvcService } from 'src/app/services/httpsvc.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
  providers: [ MessageService ]
})
export class CreateUserComponent implements OnInit, OnDestroy {
  isLog: boolean;

  createuserForm: FormGroup;

  loading = false;

  _token: string;

  subscription: Subscription;

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private formBuilder: FormBuilder, 
    private messageService: MessageService,
    private httpsvc: HttpsvcService
  ) { 
    
    if(localStorage.getItem("rememberme") == "true") {
      this._token = localStorage.getItem('_token');
    }
    else if(localStorage.getItem("rememberme") == "false") {
      this._token = sessionStorage.getItem('_token');
    }
    else {
      this._token = ""; 
    }
    //this._token = sessionStorage.getItem('_token');
  }

  checkexpiredToken(): boolean {
    let bln: boolean = true;

    if(this._token != "") {
      this.subscription = this.httpsvc.getTNY(this._token).subscribe(() => {
        bln = true;
      }, err => {
        bln = false;
        //console.log(err);
      });
    }
    else {
      bln = false;
    }

    //console.log(bln);
    return bln;
  }


  ngOnInit(): void {
    // this.isLog = this.sharedService.getGlobalInput();

    // if(!this.isLog) {
    //   this.router.navigate(['/']).then(() => {
    //     window.location.reload();
    //   });
    // }
    // else {
    //   if(sessionStorage.getItem('_role') === '0') {
    //     this.router.navigate(['/dashboard']).then(() => {
    //       window.location.reload();
    //     });
    //   }
    // }

    let blnsuccess = this.checkexpiredToken();

    if(blnsuccess) {
      const remLS = localStorage.getItem("rememberme");
      if(remLS == "false") { 
        if(sessionStorage.getItem("isLogin") == "false") {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }
        else if(sessionStorage.getItem("isLogin") == "true") {
          if(sessionStorage.getItem('_role') === '0') {
            this.router.navigate(['/dashboard']).then(() => {
              window.location.reload();
            });
          }
          else {
            this.initForm();
          }
        }
        else {
          this.sharedService.remLocalStorage();
          this.sharedService.remSessionStorage();
          this.router.navigate(['/dashboard']).then(() => {
            window.location.reload();
          });
        }
      }
      else if(remLS == "true") {
        if(localStorage.getItem('_role') === '0') {
          this.router.navigate(['/dashboard']).then(() => {
            window.location.reload();
          });
        }
        else {
          this.initForm();
        }
        
      }
      else {
        this.sharedService.remLocalStorage();
        this.sharedService.remSessionStorage();
        this.router.navigate(['/dashboard']).then(() => {
          window.location.reload();
        });
      }
    }
    else {
      this.sharedService.remLocalStorage();
      this.sharedService.remSessionStorage();
      this.router.navigate(['/dashboard']).then(() => {
        window.location.reload();
      });
    }

    
  }

  initForm() {
    this.createuserForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      role: ['0', Validators.required]
    }, {
      validator: this.mustMatch('password', 'confirmPassword') // Custom validator
    });
  }

  mustMatch(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passControl = formGroup.controls[password];
      const confirmPassControl = formGroup.controls[confirmPassword];

      if (confirmPassControl.errors && !confirmPassControl.errors.mustMatch) {
        // Return if another validator has already found an error on confirmPassword
        return;
      }

      // Set error on confirmPassword if validation fails
      if (passControl.value !== confirmPassControl.value) {
        confirmPassControl.setErrors({ mustMatch: true });
      } else {
        confirmPassControl.setErrors(null);
      }
    };
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
    
  } 

  get f() { return this.createuserForm.controls; }

  onSubmit() {
    this.loading = true;

    if (this.createuserForm.valid) {
      var input = {
        "name" : this.createuserForm.controls['name'].value,
        "email" : this.createuserForm.controls['email'].value,
        "password" : this.createuserForm.controls['password'].value,
        "confirm_password": this.createuserForm.controls['confirmPassword'].value,
        "role": this.createuserForm.controls['role'].value
      }

      this.subscription = this.httpsvc.createUser(this._token,input).subscribe(resp => {
        // console.log(resp.body);
        this.showMsgSvc('User has been created', 'success','Success');
        this.loading = false;

      }, err => {
        console.log(err);
        if(err.status == 401) {
          this.sharedService.remLocalStorage();
          this.sharedService.remSessionStorage();
          this.router.navigate(['/']).then(()=> {
            window.location.reload();
          });
        }
        this.showMsgSvc('Error encountered: ' + err, 'invalid','Error');
      });

      this.createuserForm.reset({
        role: '0' 
      });
      
      //this.loading = false;

    } else {
      this.loading = false;
      this.createuserForm.markAllAsTouched();
    }
  }

  showMsgSvc(msg: string, severe: string, summ: string) {
    this.messageService.add({key: 'tc', severity: severe, summary: summ, detail: msg}); 
  }

}
