import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { WebapiService } from './webapi.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpsvcService {
  // DEV
 //apiUrl = "http://localhost:8000/api";

  // PROD
 apiUrl = "http://salesapi.stjosephdrug.com/public/api";

  httpLink = {
    getSalesDaily: this.apiUrl + "/data/dailysales",
    getMonthlyGrowth: this.apiUrl + "/data/monthlygrowth",
    getSalesAchi: this.apiUrl + "/data/salesachievement",
    getSSSG: this.apiUrl + "/data/sssg",
    getTNY: this.apiUrl + "/data/todaynyesterday",
    getDSDetl: this.apiUrl + "/data/dailysalesdetl",
    getDSDetlchart: this.apiUrl + "/data/dailysalesdetl-chrt",
    getBranchList: this.apiUrl + "/data/branchlist",
    getSSSGrowthdetl: this.apiUrl + "/data/sssggrowthdetl",
    getS3Growthdetlch: this.apiUrl + "/data/sssggrowthdetlchart",
    getADSDetl: this.apiUrl + "/data/adsdetail",
    getMapBrnchAchi: this.apiUrl + "/data/mapbranchachievement",
    getMapBrnDlyNS: this.apiUrl + "/data/bmapdlynetsaleschart",
    getBrnchSalesDetl: this.apiUrl + "/data/branchsalesdetl",
    getMSRBranchDailyNS: this.apiUrl + "/data/msrbranchdailynetsales",
    getToken: this.apiUrl + "/login", 
    logout: this.apiUrl + "/logout",
    createUser: this.apiUrl + "/user/create",
    changePwd: this.apiUrl + "/usr/pwd/"
  }

  constructor(
    private webapi: WebapiService,
    private http: HttpClient
  ) { }

  public getDailySales(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getSalesDaily, token);
  }

  public getMonthlyGrowth(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getMonthlyGrowth, token);
  }

  public getSalesAchi(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getSalesAchi, token);
  }

  public getSSSG(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getSSSG, token);
  }

  public getTNY(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getTNY, token);
  }

  public getDSDetl(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getDSDetl, token);
  }

  public getDSDetlChart(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getDSDetlchart, token);
  }

  public getBranchList(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getBranchList, token);
  }

  public getSSSGGrowthDetl(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getSSSGrowthdetl, token);
  }

  public getSSSGGrowthDetlCh(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getS3Growthdetlch, token);
  }

  public getADSDetail(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getADSDetl, token);
  }

  public getMapBranchAchi(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getMapBrnchAchi, token);
  }

  public getMapBranchDlyNS(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getMapBrnDlyNS, token);
  }

  public getBranchSalesDetl(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getBrnchSalesDetl, token);
  }

  public getMSRBranchDlyNS(token: string): Observable<any> {
    return this.webapi.getSPs(this.httpLink.getMSRBranchDailyNS, token);
  }

  public getToken(model: any): Observable<any> {
    return this.webapi.postLogin(this.httpLink.getToken, model);
  }

  public postexit(token: string, model: any): Observable<any> {
    return this.webapi.postLogout(this.httpLink.logout, token, model);
  }

  public createUser(token: string, model: any): Observable<any> {
    return this.webapi.post(this.httpLink.createUser, token, model);
  }

  public changePwd(token: string, model: any, uid: string): Observable<any> {
    return this.webapi.put(this.httpLink.changePwd + uid, token, model);
  }


}
