<app-header></app-header>
<div class="container-fluid" style="margin-top: 120px; margin-bottom: 10px;">
    <span style="color: silver; font-weight: 500; font-size:17px">Last Update: {{ strDate }} </span>
  </div>
<div class="container-fluid">
    <div class="row g-3">
        <div class="col-12">
            <div class="card">
                <div class="card-header text-center" style="color: #676767; background-color: #f4f4f7; padding:5px;">
                    <span style="font-weight: bold; font-size: 30px;">YTD NET SALES</span>
                </div>
                <div class="card-content">
                    <div class="row g-3">
                        <div class="col-12 col-md-6">
                            <div class="text-center" style="background-color: white;">
                                <span style="font-size: 38px; font-weight: 500; color:#676767"> {{_totalNSCurrent | currency:'&#8369;'}}</span><br>
                                <span style="font-size: 20px; color: #999a98; font-weight: 400"> {{_totalNSPrevious | currency:'&#8369;'}} (</span>
                                <i [ngClass]="{'bi-caret-up-fill': ((_totalNSCurrent - _totalNSPrevious) / _totalNSPrevious * 100) > 0, 'bi-caret-down-fill': ((_totalNSCurrent - _totalNSPrevious) / _totalNSPrevious * 100) < 0 }"
                                    class="bi"></i>
                                <span style="font-size: 20px;" [ngClass]="{'positive': ((_totalNSCurrent - _totalNSPrevious) / _totalNSPrevious * 100) > 0, 'negative': (_totalNSCurrent - _totalNSPrevious) / _totalNSPrevious * 100 < 0}">
                                    {{ (_totalNSCurrent - _totalNSPrevious) / _totalNSPrevious * 100 |  number:'1.2-2'}}%
                                </span>
                                <span style="font-size: 20px; color: #999a98; font-weight: 400">vs. yr-ago)</span>
                                  
                                
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="row g-3 text-center" style="color: #999a98">
                                <div class="col-12 col-md-6" style="padding: 10px;">
                                    <!-- <span style="text-decoration: underline;">TODAY</span><br> -->
                                    <span style="text-decoration: underline;">{{_dtToday}} </span><br> 
                                    <span>{{_cntBranchtoday}} out of {{_actBranchtoday}} branches </span><br>
                                    <span style="font-size: 25px; color: #676767">{{_todaynet | currency:'&#8369;'}} </span>
                                </div>
                                <div class="col-12 col-md-6" style="padding: 10px;">
                                    <!-- <span style="text-decoration: underline;">YESTERDAY</span><br> -->
                                    <span style="text-decoration: underline;">{{_dtYesterday}} </span><br>
                                    <span>{{_cntBranchyest}} out of {{_actBranchyest}} branches</span><br>
                                    <span style="font-size: 25px; color: #676767">{{_yestnet | currency:'&#8369;'}} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
        
    </div>
    
    <div class="row g-3" style="margin: 0px">
        <div class="col-12 text-center" style="background-color: #dbe8d8; border-radius: 5px;">
            <span style="font-size: 23px; font-weight: bold; color:#808080"> AS OF {{_dtToday}}</span>
        </div>
    </div>
    <br>
    <div class="row g-3">
        <div class="col-12 col-md-6">
            <div class="card">
                <div class="card-header">
                    <span style="font-size: 25px; font-weight: bold; color: #7d7c7c">YTD ADS : {{totalnetsales | currency:'&#8369;'}}</span>
                </div>    
                <div class="card-content" style="padding: 5px">
                    <div *ngIf="isLoadingc1" class="spinner-container">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    <div *ngIf="!isLoadingc1">
                        <div *ngIf="chartOptions">
                            <br>
                            <highcharts-chart
                                [Highcharts]="Highcharts"
                                [options]="chartOptions"
                                style="width: 100%; height: 400px; display: block;"
                            ></highcharts-chart>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="card">
                <div class="card-header">
                    <span style="font-size: 25px; font-weight: bold; color: #7d7c7c">SALES ACHIEVEMENT : {{salesachievement | number:'1.2-2' }}%</span>
                </div>
                <div class="card-content" style="padding: 5px">
                    <div *ngIf="isLoadingc3" class="spinner-container">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    <div *ngIf="!isLoadingc3">
                        <div *ngIf="chartOptions2" class="chart-container">
                            <highcharts-chart 
                                [Highcharts]="Highcharts" 
                                [options]="chartOptions2" 
                                style="width: 100%; height: 400px; display: block;">
                            </highcharts-chart>
                            <br>
                            <br>
                        </div>
                    </div>
                   
                </div>
            </div>
            
        </div>
        <div class="col-12 col-md-6">
            <div class="card">
                <div class="card-header">
                    <span style="font-size: 25px; font-weight: bold; color: #7d7c7c">OVERALL GROWTH : {{overallgrowth | number:'1.2-2' }}%</span>
                </div>
                <div class="card-content" style="padding: 5px">
                    <div *ngIf="isLoadingc2" class="spinner-container">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    <div *ngIf="!isLoadingc2">
                        <div *ngIf="chartOptions1">
                            <br>
                            <highcharts-chart
                                [Highcharts]="Highcharts"
                                [options]="chartOptions1"
                                style="width: 100%; height: 400px; display: block;"
                            ></highcharts-chart>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="card">
                <div class="card-header">
                    <span style="font-size: 25px; font-weight: bold; color: #7d7c7c">SSSG GROWTH : {{sssggrowth | number:'1.2-2' }}%</span>
                </div>
                <div class="card-content" style="padding: 5px">
                    <div *ngIf="isLoadingc4" class="spinner-container">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    <div *ngIf="!isLoadingc4">
                        <div *ngIf="chartOptions3" class="text-center">
                            <br>
                            <highcharts-chart
                                [Highcharts]="Highcharts"
                                [options]="chartOptions3"
                                style="width: 100%; height: 400px; display: block;"
                            ></highcharts-chart>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
</div>

<p-dialog [(visible)]="displayModal" header="OVERALL GROWTH - COMPARATIVE REPORT ON MONTHLY NET SALES ({{_sFltrMonth}})" [modal]="false" 
    [closable]="true" [dismissableMask]="false" [style]="{width: '50vw'}" [resizable]="false" [maximizable]="true" (onHide)="onDialogHide('growth')"> 
    <div class="ui-g">
      <div class="container">
        <div class="row g-3">
            <div class="col-12 col-md-4">
                <div class="card">
                    <div class="card-header text-center tbl-summ-header">
                        {{_sFltrMonth}}  <i style="font-size: 15px">(current)</i>
                    </div>
                    <div class="card-content tbl-summ-val text-center">
                        <span>{{_DSTotlNSytd | currency:'&#8369; '}}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card">
                    <div class="card-header text-center tbl-summ-header">
                        {{_sFltrMonth}} <i style="font-size: 15px">(yrago)</i>
                    </div>
                    <div class="card-content tbl-summ-val text-center">
                        <span style="color:#999a98">{{_DSTotlNSyrago | currency:'&#8369; '}}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card">
                    <div class="card-header text-center tbl-summ-header">
                        {{_sFltrMonth}} - GROWTH
                    </div>
                    <div class="card-content tbl-summ-val text-center">
                        <i [ngClass]="{'bi-caret-up-fill': _DSTotlGrowth > 0, 'bi-caret-down-fill': _DSTotlGrowth < 0 }"
                            class="bi"></i>
                        <span [ngClass]="{'positive': _DSTotlGrowth > 0, 'negative': _DSTotlGrowth < 0}">{{_DSTotlGrowth | number:'1.2-2'}}%</span>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row g-3">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <span style="font-weight: bold; font-size: 15px; color:#676767">DAILY NET SALES</span>
                    </div>
                    <div class="card-content">
                        <div *ngIf="isLoadingc5" class="spinner-container">
                            <div class="spinner-border" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                        <div *ngIf="!isLoadingc5">
                            <div *ngIf="chartOptions4" style="display: flex; justify-content: center;">
                                <br>
                                <highcharts-chart
                                    [Highcharts]="Highcharts"
                                    [options]="chartOptions4"
                                    style="width: 50vw; height: 200px; display: block;"
                                ></highcharts-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <p-table [value]="_flterDSMonth" [scrollable]="true" scrollHeight="400px" [columns]="_tblCols_dsDetl" #_xdt id="mytbl" 
            sortMode="single" [sortOrder]="sortOrder3" [(selection)]="selgrowth" dataKey="bname" selectionMode="single">
            <ng-template pTemplate="caption">
                <div class="ui-helper-clearfix" style="text-align: left">
                    <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="CSV" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                    <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="exportExcel(_sFltrMonth, 'OVERALL GROWTH', 'overallgrowth')" style="margin-right: 0.5em;" class="ui-button-success"></button>
                    <button type="button" pButton icon="pi pi-file-pdf" iconPos="left" label="PDF" (click)="exportPdf(_sFltrMonth, 'OVERALL GROWTH','overallgrowth')" class="ui-button-warning"></button>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns >
                <tr>
                    <th *ngFor="let col of columns" class="text-center" pSortableColumn="{{ col.field }}" title="Click column header to sort desc|asc.">
                        {{col.header}}
                        <p-sortIcon field="{{ col.field }}"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-selected="selected">
                <tr [pSelectableRow]="rowData" [class.selected-row]="selected">
                    <ng-container *ngFor="let col of columns">
                        <ng-container [ngSwitch]="col.field">
                            <ng-container *ngSwitchCase="'netsalescurr'">
                                <td style="text-align: right; font-weight: bold;">{{ rowData[col.field] | currency:'&#8369; ' }}</td>
                            </ng-container>
                            <ng-container *ngSwitchCase="'netsalesprev'">
                                <td style="text-align: right; ">{{ rowData[col.field] | currency:'&#8369; ' }}</td>
                            </ng-container>
                            <ng-container *ngSwitchCase="'growth'">
                                <td style="text-align: right;" [ngClass]="{'positive': rowData[col.field] > 0, 'negative': rowData[col.field] < 0}">
                                    <i [ngClass]="{'bi-caret-up-fill': rowData[col.field] > 0, 'bi-caret-down-fill': rowData[col.field] < 0 }"
                                        class="bi-tbl"></i>
                                        {{ rowData[col.field] | number: '1.2-2' }}</td>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <td>{{ rowData[col.field] }}</td>
                            </ng-container>
                        </ng-container>
                    </ng-container>    
                </tr>
            </ng-template>
        </p-table>
      </div>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" label="Close" (click)="displayModal = false"></button>
    </p-footer>
</p-dialog>


<!-- Bootstrap Modal -->
<div id="modalADS" class="modal" role="dialog" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #7b95a3; color: white">
          <h6 class="modal-title">ADS - MONTHLY SALES REPORT ({{_sFltrMonth2}})</h6>
          <!-- <button type="button" class="close" (click)="closeModalADS()">
            <span>&times;</span>
          </button> -->
          <!-- <button type="button" class="btn-close" (click)="closeModalADS('modalADS')"></button> -->
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div class="row g-3">
                    <div class="card">
                        <div class="card-header">SUMMARY</div>
                        <div class="card-content text-center">
                            <div class="row g-3">
                                <div class="col-12 col-md-4">
                                    <span style="font-size: 15px; color: #7d7c7c;">Total Net Sales for the Month</span><br>
                                    <span style="font-size: 20px; color:#676767">{{_adsDetl_currtotsalesmonth | currency:'&#8369; '}}</span>
                                </div>
                                <div class="col-12 col-md-4">
                                    <span style="font-size: 15px; color: #7d7c7c;">ADS for the Month</span><br>
                                    <span style="font-size: 20px; color:#676767">{{_adsDetl_curradsmonth | currency:'&#8369; '}}</span>
                                </div>
                                <div class="col-12 col-md-4">
                                    <span style="font-size: 15px; color: #7d7c7c;">Sales Quota</span><br>
                                    <span style="font-size: 20px; color:#676767">{{_adsDetl_salesquota | currency:'&#8369; '}}</span>
                                </div>
                            </div>
                            <br>
                            <div class="row g-3">
                                <div class="col-12 col-md-4">
                                    <span style="font-size: 15px; color: #7d7c7c;">Sales Achievement</span><br>
                                    <span style="font-size: 20px; color: #676767;">{{_adsDetl_salesachi | number:'1.2-2' }}%</span>
                                </div>
                                <div class="col-12 col-md-4">
                                    <span style="font-size: 15px; color: #7d7c7c;">Prev Year ADS</span><br>
                                    <span style="font-size: 20px; color:#676767">{{_adsDetl_prevadsmonth | currency:'&#8369; '}}</span>
                                </div>
                                <div class="col-12 col-md-4">
                                    <span style="font-size: 15px; color: #7d7c7c;">Growth vs. Prev Year</span><br>
                                    <span style="font-size: 20px; " [ngClass]="{'positive': _adsDetl_growthprevyr > 0, 'negative': _adsDetl_growthprevyr < 0}">
                                        <i [ngClass]="{'bi-caret-up-fill': _adsDetl_growthprevyr > 0, 'bi-caret-down-fill': _adsDetl_growthprevyr < 0 }"
                                            class="bi-tbl"></i>
                                        {{_adsDetl_growthprevyr | number:'1.2-2' }}%</span>
                                </div>
                            </div>
                            <br>
                            <div class="row g-3">
                                <div class="col-12 col-md-4">
                                    <span style="font-size: 15px; color: #7d7c7c;">YTD ADS</span><br>
                                    <span style="font-size: 20px; color:#676767">{{_adsDetl_ytdads | currency:'&#8369; '}}</span>
                                </div>
                                <div class="col-12 col-md-4">
                                    <span style="font-size: 15px; color: #7d7c7c;">Prev Year YTD ADS</span><br>
                                    <span style="font-size: 20px; color:#676767">{{_adsDetl_yragoytdads | currency:'&#8369; '}}</span>
                                </div>
                                <div class="col-12 col-md-4">
                                    <span style="font-size: 15px; color: #7d7c7c;">YTD Growth</span><br>
                                    <span style="font-size: 20px; " [ngClass]="{'positive': _adsDetl_ytdgrowth > 0, 'negative': _adsDetl_ytdgrowth < 0}">
                                        <i [ngClass]="{'bi-caret-up-fill': _adsDetl_ytdgrowth > 0, 'bi-caret-down-fill': _adsDetl_ytdgrowth < 0 }"
                                            class="bi-tbl"></i>
                                        {{_adsDetl_ytdgrowth | number:'1.2-2' }}%</span>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <br>
                <!-- <p style="color: #d07575" -->
                <div class="row g-3">
                    <div class="card">
                        <div class="card-header">DAILY NET SALES</div>
                        <div class="card-content text-center">
                            <div *ngIf="isLoadingc7" class="spinner-container">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                            <div *ngIf="!isLoadingc7">
                                <div *ngIf="chartOptions6"  style="display: flex;
                                justify-content: center;">
                                    <br>
                                    <highcharts-chart
                                        [Highcharts]="Highcharts"
                                        [options]="chartOptions6"
                                        style="width: 100%; height: 200px; display: block; "
                                    ></highcharts-chart>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
                <br>
                <p-table #dty [value]="_dtFlterMOBranchDetl" [scrollable]="true" scrollHeight="350px" sortMode="single" [responsive]="true"
                [sortOrder]="sortOrder1" id="_tblmonthlyBrnchDetl" selectionMode="single" dataKey="s_brncname" [(selection)]="selmsr" [reorderableColumns]="true"
                [columns]="_tblCols_brnchmodetl">
                <ng-template pTemplate="caption">
                    <div class="ui-helper-clearfix" style="text-align: left">
                        <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="CSV" (click)="dty.exportCSV()" style="margin-right: 0.5em;"></button>
                        <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="exportExcel(_sFltrMonth2, 'MONTHLY SALES REPORT','msr')" style="margin-right: 0.5em;" class="ui-button-success"></button>
                        <button type="button" pButton icon="pi pi-file-pdf" iconPos="left" label="PDF" (click)="exportPdf(_sFltrMonth2, 'MONTHLY SALES REPORT', 'msr')" class="ui-button-warning"></button>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" style="width: 200px;" class="text-center" pSortableColumn="{{ col.field }}" title="Click column header to sort desc|asc.">
                            {{col.header}}
                            <p-sortIcon field="{{ col.field }}"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-selected="selected">
                  <tr [pSelectableRow]="rowData" [class.selected-row]="selected">
                    <ng-container *ngFor="let col of _tblCols_brnchmodetl">
                      <!-- <td>{{ itm[col.field] }}</td> -->
                      <ng-container [ngSwitch]="col.field">
                        <ng-container *ngSwitchCase="'s_brncname'">
                            <td style="width: 200px; " class="h">
                                <span class="ui-column-title">
                                    <a (click)="showMSRBranchDailyNS(_sFltrMonth2,rowData[col.field])" title="Click to see daily netsales." style="cursor: pointer; ">{{col.header}} :</a>
                                </span><a (click)="showMSRBranchDailyNS(_sFltrMonth2,rowData[col.field])" title="Click to see daily netsales." style="cursor: pointer; color: #4390d3; text-decoration: underline;">{{ rowData[col.field] }}</a>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'ytd_netsales'">
                            <td style="width: 200px; ">
                                <span class="ui-column-title">{{col.header}} :</span>{{ rowData[col.field] | currency:'&#8369; ' }}
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'ytdads'">
                            <td style="width: 200px;"><span class="ui-column-title">{{col.header}} :</span>{{ rowData[col.field] | currency:'&#8369; ' }}</td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'quotasale'">
                            <td style="width: 200px;"><span class="ui-column-title">{{col.header}} :</span>{{ rowData[col.field] | currency:'&#8369; ' }}</td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'salesachi'">
                            <td style="width: 200px;"><span class="ui-column-title">{{col.header}} :</span>{{ rowData[col.field] | number:'1.2-2' }}</td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'yragoads'">
                            <td style="width: 200px;"><span class="ui-column-title">{{col.header}} :</span>{{ rowData[col.field] | currency:'&#8369; ' }}</td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'growthads'">
                            <td style="width: 200px;" [ngClass]="{'positive': rowData[col.field] > 0, 'negative': rowData[col.field] < 0}">
                                <span class="ui-column-title">{{col.header}} :</span><i [ngClass]="{'bi-caret-up-fill': rowData[col.field] > 0, 'bi-caret-down-fill': rowData[col.field] < 0 }"
                                    class="bi-tbl"></i>
                                    {{ rowData[col.field] | number: '1.2-2' }}</td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'running_ytads'">
                            <td style="width: 200px;"><span class="ui-column-title">{{col.header}} :</span>{{ rowData[col.field] | currency:'&#8369; ' }}</td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'running_yragoads'">
                            <td style="width: 200px;"><span class="ui-column-title">{{col.header}} :</span>{{ rowData[col.field] | currency:'&#8369; ' }}</td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'yrago_growthads'">
                            <td style="width: 200px;" [ngClass]="{'positive': rowData[col.field] > 0, 'negative': rowData[col.field] < 0}">
                                <span class="ui-column-title">{{col.header}} :</span><i [ngClass]="{'bi-caret-up-fill': rowData[col.field] > 0, 'bi-caret-down-fill': rowData[col.field] < 0 }"
                                    class="bi-tbl"></i>
                                    {{ rowData[col.field] | number: '1.2-2' }}</td>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <td style="width: 200px;"><span class="ui-column-title">{{col.header}} :</span>{{ rowData[col.field] }}</td>
                        </ng-container>
                      </ng-container>
                      
                    </ng-container>
                  </tr>
                </ng-template>
              
              </p-table>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeModalADS('modalADS')" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
</div>

<div id="modalADSDetl" class="modal" role="dialog" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg" role="document" >
            <div class="modal-content">
                <div *ngIf="isLoadingMSRtbl" class="spinner-container">
                    <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
                <div *ngIf="!isLoadingMSRtbl">
                    <div class="modal-header" style="background-color: #3a9f86; color: white">
                        <h6 class="modal-title">{{_sFltrMonth3}} DAILY NET SALES ({{_sBranchName}}) </h6>
                    </div>
                    <div class="modal-body">
                        <p-table [value]="_dtFlterMSRTableDailyNS" [scrollable]="true" scrollHeight="350px" [columns]="_tblCols_msrDetl" #dta>
                            <ng-template pTemplate="caption">
                                <div class="ui-helper-clearfix" style="text-align: left">
                                    DETAILS
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns" class="text-center">
                                        {{col.header}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr>
                                    <ng-container *ngFor="let col of columns">
                                        <ng-container [ngSwitch]="col.field">
                                            <ng-container *ngSwitchCase="'netsales'">
                                                <td>{{ rowData[col.field] | currency:'&#8369; ' }}</td>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'custcnt'">
                                                <td>{{ rowData[col.field] }}</td>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                <td>{{ rowData[col.field] }}</td>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>    
                                </tr>
                            </ng-template>
                        </p-table>
                        <br>
                        <div class="row g-3">
                            <div class="col-9">
                                <div class="card">
                                    <div class="card-header">SUMMARY</div>
                                    <div class="card-content">
                                        <div class="row container-fluid">
                                            <div class="col-md-6 lbl-msr"><span>TOTAL NET SALES</span></div>
                                            <div class="col-md-6 lbl-msr-val"><span>{{_tblmsr_totlnetsales | currency:'&#8369; ' }}</span></div>
                                            <div class="col-md-6 lbl-msr"><span>ADS</span></div>
                                            <div class="col-md-6 lbl-msr-val"><span>{{_tblmsr_ads | currency:'&#8369; ' }}</span></div>
                                            <div class="col-md-6 lbl-msr"><span>QUOTA</span></div>
                                            <div class="col-md-6 lbl-msr-val"><span>{{_tblmsr_quota | currency:'&#8369; ' }}</span></div>
                                            <div class="col-md-6 lbl-msr"><span>SALES ACHIEVEMENT %</span></div>
                                            <div class="col-md-6 lbl-msr-val"><span>{{_tblmsr_achi | number:'1.2-2' }}%</span></div>
                                            <div class="col-md-6 lbl-msr"><span>ADS PREV YR</span></div>
                                            <div class="col-md-6 lbl-msr-val"><span>{{_tblmsr_adsprevyr | currency:'&#8369; ' }}</span></div>
                                            <div class="col-md-6 lbl-msr"><span>GROWTH vs. PREV YR</span></div>
                                            <div class="col-md-6 lbl-msr-val"><span [ngClass]="{'positive': _tblmsr_growthprevyr> 0, 'negative': _tblmsr_growthprevyr < 0}">
                                                <i [ngClass]="{'bi-caret-up-fill': _tblmsr_growthprevyr > 0, 'bi-caret-down-fill': _tblmsr_growthprevyr < 0 }"
                                                class="bi-tbl"></i>{{_tblmsr_growthprevyr | number:'1.2-2' }}%</span></div>
                                            <div class="col-md-6 lbl-msr"><span>YTD ADS</span></div>
                                            <div class="col-md-6 lbl-msr-val"><span>{{_tblmsr_ytdads | currency:'&#8369; ' }}</span></div>
                                            <div class="col-md-6 lbl-msr"><span>PREV YTD ADS</span></div>
                                            <div class="col-md-6 lbl-msr-val"><span>{{_tblmsr_prevytdads | currency:'&#8369; ' }}</span></div>
                                            <div class="col-md-6 lbl-msr"><span>YTD GROWTH</span></div>
                                            <div class="col-md-6 lbl-msr-val"><span [ngClass]="{'positive': _tblmsr_ytdgrowth> 0, 'negative': _tblmsr_ytdgrowth < 0}">
                                                <i [ngClass]="{'bi-caret-up-fill': _tblmsr_ytdgrowth > 0, 'bi-caret-down-fill': _tblmsr_ytdgrowth < 0 }"
                                                class="bi-tbl"></i>{{_tblmsr_ytdgrowth | number:'1.2-2' }}%</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" (click)="closeModalADS('modalADSDetl')" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
    </div>
</div>

<div id="modalOVG" class="modal" role="dialog" tabindex="-1"  aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background-color: #7b95a3; color: white">
                <h6 class="modal-title">OVERALL GROWTH - COMPARATIVE REPORT ON MONTHLY NET SALES ({{_sFltrMonth}})</h6>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row g-3">
                        <div class="col-12 col-md-4">
                            <div class="card">
                                <div class="card-header text-center tbl-summ-header">
                                    {{_sFltrMonth}}  <i style="font-size: 15px">(current)</i>
                                </div>
                                <div class="card-content tbl-summ-val text-center">
                                    <span>{{_DSTotlNSytd | currency:'&#8369; '}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="card">
                                <div class="card-header text-center tbl-summ-header">
                                    {{_sFltrMonth}} <i style="font-size: 15px">(yrago)</i>
                                </div>
                                <div class="card-content tbl-summ-val text-center">
                                    <span style="color:#999a98">{{_DSTotlNSyrago | currency:'&#8369; '}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="card">
                                <div class="card-header text-center tbl-summ-header">
                                    {{_sFltrMonth}} - GROWTH
                                </div>
                                <div class="card-content tbl-summ-val text-center">
                                    <i [ngClass]="{'bi-caret-up-fill': _DSTotlGrowth > 0, 'bi-caret-down-fill': _DSTotlGrowth < 0 }"
                                        class="bi"></i>
                                    <span [ngClass]="{'positive': _DSTotlGrowth > 0, 'negative': _DSTotlGrowth < 0}">{{_DSTotlGrowth | number:'1.2-2'}}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row g-3">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <span style="font-weight: bold; font-size: 15px; color:#676767">DAILY NET SALES</span>
                                </div>
                                <div class="card-content">
                                    <div *ngIf="isLoadingc5" class="spinner-container">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                    </div>
                                    <div *ngIf="!isLoadingc5">
                                        <div *ngIf="chartOptions4" style="display: flex; justify-content: center;">
                                            <br>
                                            <highcharts-chart
                                                [Highcharts]="Highcharts"
                                                [options]="chartOptions4"
                                                style="width: 100%; height: 200px; display: block;"
                                            ></highcharts-chart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <p-table [value]="_flterDSMonth" [scrollable]="true" scrollHeight="300px" [columns]="_tblCols_dsDetl" #dt id="mytbl" 
                        sortMode="single" [sortOrder]="sortOrder3" [(selection)]="selgrowth" dataKey="bname" selectionMode="single" [responsive]="true">
                        <ng-template pTemplate="caption">
                            <div class="ui-helper-clearfix" style="text-align: left">
                                <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="CSV" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                                <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="exportExcel(_sFltrMonth, 'OVERALL GROWTH', 'overallgrowth')" style="margin-right: 0.5em;" class="ui-button-success"></button>
                                <button type="button" pButton icon="pi pi-file-pdf" iconPos="left" label="PDF" (click)="exportPdf(_sFltrMonth, 'OVERALL GROWTH','overallgrowth')" class="ui-button-warning"></button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns >
                            <tr>
                                <th *ngFor="let col of columns" class="text-center" pSortableColumn="{{ col.field }}" title="Click column header to sort desc|asc.">
                                    {{col.header}}
                                    <p-sortIcon field="{{ col.field }}"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-selected="selected">
                            <tr [pSelectableRow]="rowData" [class.selected-row]="selected">
                                <ng-container *ngFor="let col of columns">
                                    <ng-container [ngSwitch]="col.field">
                                        <ng-container *ngSwitchCase="'netsalescurr'">
                                            <td><span class="ui-column-title">{{col.header}} :</span>{{ rowData[col.field] | currency:'&#8369; ' }}</td>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'netsalesprev'">
                                            <td><span class="ui-column-title">{{col.header}} :</span>{{ rowData[col.field] | currency:'&#8369; ' }}</td>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'growth'">
                                            <td [ngClass]="{'positive': rowData[col.field] > 0, 'negative': rowData[col.field] < 0}">
                                                <span class="ui-column-title">{{col.header}} :</span><i [ngClass]="{'bi-caret-up-fill': rowData[col.field] > 0, 'bi-caret-down-fill': rowData[col.field] < 0 }"
                                                    class="bi-tbl"></i>
                                                    {{ rowData[col.field] | number: '1.2-2' }}</td>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <td><span class="ui-column-title">{{col.header}} :</span>{{ rowData[col.field] }}</td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>    
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeModalADS('modalOVG')" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div id="modalSSSG" class="modal" role="dialog" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background-color: #7b95a3; color: white">
                <h6 class="modal-title">SSSG GROWTH - COMPARATIVE REPORT ON MONTHLY NET SALES ({{_sFltrMonth1}})</h6>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row g-3">
                        <div class="col-12 col-md-4">
                            <div class="card">
                                <div class="card-header text-center tbl-summ-header">
                                    {{_sFltrMonth1}}  <i style="font-size: 15px">(current)</i>
                                </div>
                                <div class="card-content tbl-summ-val text-center">
                                    <span>{{_sssgdtl_nsytd | currency:'&#8369; '}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="card">
                                <div class="card-header text-center tbl-summ-header">
                                    {{_sFltrMonth1}} <i style="font-size: 15px">(yrago)</i>
                                </div>
                                <div class="card-content tbl-summ-val text-center">
                                    <span style="color:#999a98">{{_sssgdtl_nsyrago | currency:'&#8369; '}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="card">
                                <div class="card-header text-center tbl-summ-header">
                                    {{_sFltrMonth1}} - GROWTH
                                </div>
                                <div class="card-content tbl-summ-val text-center">
                                    <i [ngClass]="{'bi-caret-up-fill': _sssgdtl_nsgrowth > 0, 'bi-caret-down-fill': _sssgdtl_nsgrowth < 0 }"
                                        class="bi"></i>
                                    <span [ngClass]="{'positive': _sssgdtl_nsgrowth > 0, 'negative': _sssgdtl_nsgrowth < 0}">{{_sssgdtl_nsgrowth | number:'1.2-2'}}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row g-3">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <span style="font-weight: bold; font-size: 15px; color:#676767">DAILY NET SALES</span>
                                </div>
                                <div class="card-content">
                                    <div *ngIf="isLoadingc6" class="spinner-container">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                    </div>
                                    <div *ngIf="!isLoadingc6">
                                        <div *ngIf="chartOptions5" style="display: flex; justify-content: center;">
                                            <br>
                                            <highcharts-chart
                                                [Highcharts]="Highcharts"
                                                [options]="chartOptions5"
                                                style="width: 100%; height: 200px; display: block;"
                                            ></highcharts-chart>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <p-table [value]="_dtFlterSSSGDetl" [scrollable]="true" scrollHeight="300px" [columns]="_tblCols_sssgDetl" #dtx [responsive]="true"
                        sortMode="single" [sortOrder]="sortOrder2" [(selection)]="selsssg" dataKey="bname" selectionMode="single">
                        <ng-template pTemplate="caption">
                            <div class="ui-helper-clearfix" style="text-align: left">
                                <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="CSV" (click)="dtx.exportCSV()" style="margin-right: 0.5em;"></button>
                                <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="exportExcel(_sFltrMonth1, 'SSSG GROWTH','sssgrowth')" style="margin-right: 0.5em;" class="ui-button-success"></button>
                                <button type="button" pButton icon="pi pi-file-pdf" iconPos="left" label="PDF" (click)="exportPdf(_sFltrMonth1, 'SSSG GROWTH', 'sssgrowth')" class="ui-button-warning"></button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" class="text-center" pSortableColumn="{{ col.field }}" title="Click column header to sort desc|asc.">
                                    {{col.header}}
                                    <p-sortIcon field="{{ col.field }}"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-selected="selected">
                            <tr [pSelectableRow]="rowData" [class.selected-row]="selected">
                                <ng-container *ngFor="let col of columns">
                                    <ng-container [ngSwitch]="col.field">
                                        <ng-container *ngSwitchCase="'netsalescurr'">
                                            <td>
                                                <span class="ui-column-title">{{col.header}} :</span>
                                                {{ rowData[col.field] | currency:'&#8369; ' }}
                                            </td>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'netsalesprev'">
                                            <td><span class="ui-column-title">{{col.header}} :</span>
                                                {{ rowData[col.field] | currency:'&#8369; ' }}
                                            </td>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'growth'">
                                            <td [ngClass]="{'positive': rowData[col.field] > 0, 'negative': rowData[col.field] < 0}">
                                                <span class="ui-column-title">{{col.header}} :</span>
                                                <i [ngClass]="{'bi-caret-up-fill': rowData[col.field] > 0, 'bi-caret-down-fill': rowData[col.field] < 0 }"
                                                    class="bi-tbl"></i>
                                                    {{ rowData[col.field] | number: '1.2-2' }}</td>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <td><span class="ui-column-title">{{col.header}} :</span>{{ rowData[col.field] }}</td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>    
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeModalADS('modalSSSG')" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>