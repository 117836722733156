<app-header></app-header>
<div style="margin-top: 120px;"></div>
<div class="container-fluid">
    <div class="row justify-content-center g-3">
        <div class="col-12 col-md-6 col-md-3">
            <div class="col-lg-12">
                <a class="btn btn-success" [routerLink]="['/dashboard']"><i class="bi bi-house-fill"></i> Dashboard</a>
            </div>
            <br>
            <div class="card">
                <div class="card-header">
                    <span style="font-size: 18px; font-weight: bold; color: #676767">CHANGE PASSWORD</span>
                </div>
                <div class="card-content">
                    <div class="col-12" style="padding:20px">
                        <form [formGroup]="changepwdForm" (ngSubmit)="onSubmit()">
                            <!-- Password -->
                            <div class="form-group">
                                <label for="password">New Password</label>
                                <input type="password" id="password" class="form-control" formControlName="password" [ngClass]="{'is-invalid': f.password.touched && f.password.invalid}" />
                                <div *ngIf="f.password.touched && f.password.invalid" class="invalid-feedback">
                                  <div *ngIf="f.password.errors?.required">Password is required</div>
                                </div>
                              </div>
                              <br>
                              <!-- Confirm Password -->
                              <div class="form-group">
                                <label for="confirmPassword">Confirm Password</label>
                                <input
                                  type="password"
                                  id="confirmPassword"
                                  class="form-control"
                                  formControlName="confirmPassword"
                                  [ngClass]="{'is-invalid': f.confirmPassword.touched && f.confirmPassword.invalid}"
                                />
                                <div *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid" class="invalid-feedback">
                                  <div *ngIf="f.confirmPassword.errors?.required">Confirm password is required</div>
                                  <div *ngIf="f.confirmPassword.errors?.mustMatch">Passwords must match</div>
                                </div>
                              </div>
                              <br>
                              <button class="btn btn-primary" [disabled]="loading" type="submit" style="font-size: 16px; font-weight: bold">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Change Password
                              </button>
                        </form>
                        <p-toast position="center" key="tc"></p-toast>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
