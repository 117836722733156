import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  private isUserLoggedIn: boolean;
  //private _key = 'a2jkjgz35z0';

  getGlobalInput(): any {
    //return this.isUserLoggedIn;
    //return JSON.parse(sessionStorage.getItem(this._key) || 'null');
    return sessionStorage.getItem("isLogin");
  }

  setGlobalInput(data: any): void {
    //this._key = data;
    sessionStorage.setItem("isLogin", data);
  }

  setReMe(data: any): void {
    localStorage.setItem('rememberme', data);
  }

  getReMe(): any {
    return localStorage.getItem('rememberme');
  }

  remLocalStorage(): void {
    localStorage.removeItem("_token");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("_role");
    localStorage.removeItem("_name");
    localStorage.removeItem("_usrid");
    localStorage.removeItem("rememberme");
  }

  remSessionStorage(): void {
    sessionStorage.removeItem("_token");
    sessionStorage.removeItem("isLogin");
    sessionStorage.removeItem("_role");
    sessionStorage.removeItem("_name");
    sessionStorage.removeItem("_usrid");
    sessionStorage.removeItem("rememberme");
    localStorage.removeItem("rememberme");
  }
}
