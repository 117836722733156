import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpsvcService } from 'src/app/services/httpsvc.service';
import { SharedService } from 'src/app/services/shared.service';



declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() public title: string;
  @Input() public isUserLoggedIn: boolean;


  isRole: boolean = false;
  _role: number;
  _uname: string;

  constructor(
    private httpService: HttpsvcService,
    private router: Router,
    private sharedService: SharedService
  ) { 
    
    if(localStorage.getItem("rememberme")) {
      this._uname = localStorage.getItem("_name");
      this._role = Number(localStorage.getItem("_role"));
    }
    else {
      this._uname = sessionStorage.getItem("_name");
      this._role = Number(sessionStorage.getItem("_role"));
    }
    

  }

  ngOnInit(): void {
    //this.isUserLoggedIn = this.sharedService.getGlobalInput();
    //this._role = Number(sessionStorage.getItem('_role'));
    if(this._role == 1) {
      this.isRole = true;
    }


    if(localStorage.getItem("rememberme") == "true") {
      this._uname = localStorage.getItem("_name");
      this._role = Number(localStorage.getItem("_role"));
    }
    else {
      this._uname = sessionStorage.getItem("_name");
      this._role = Number(sessionStorage.getItem("_role"));
    }

    if(this._role == 1) {
      this.isRole = true;
    }

    console.log(this._uname);
    console.log(this._role);
  }

  // ngAfterViewInit() {
  //   $('.dropdown-toggle').dropdown(); // Initialize dropdown
  // }

  isActive(route: string): boolean {
    return this.router.url === route;
  }

  logout() {
    // this.sharedService.setGlobalInput(false);
    let _token: any;

    if(localStorage.getItem("rememberme") == "true") {
      _token = localStorage.getItem("_token");
    }
    else {
      _token = sessionStorage.getItem("_token");
    }

    this.httpService.postexit(_token, {}).subscribe(async data => {
      if(data.body.success) {
        if(localStorage.getItem("rememberme") == "true") {
          this.remLocalStorage();
        }
        else {
          this.remSessionStorage();
        }
        
        this.router.navigate(['/']).then(()=> {
          window.location.reload();
        });
     }
    }, async error => {
      // sessionStorage.removeItem("isLogin");
      // sessionStorage.removeItem("_token");
      if(localStorage.getItem("rememberme") == "true") {
        this.remLocalStorage();
      }
      else {
        this.remSessionStorage();
      }

      this.router.navigate(['/']).then(()=> {
        window.location.reload();
      });
    });
    
  }

  remLocalStorage() {
    localStorage.removeItem("_token");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("_role");
    localStorage.removeItem("_name");
    localStorage.removeItem("_usrid");
    localStorage.removeItem("rememberme");
  }

  remSessionStorage() {
    sessionStorage.removeItem("_token");
    sessionStorage.removeItem("isLogin");
    sessionStorage.removeItem("_role");
    sessionStorage.removeItem("_name");
    sessionStorage.removeItem("_usrid");
    sessionStorage.removeItem("rememberme");
    localStorage.removeItem("rememberme");
  }

  clickCreateuser() {
    this.router.navigate(['/create-user']);
  }

  clickChangePwd() {
    this.router.navigate(['/change-password']);
  }

}
