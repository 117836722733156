<!-- <p>branch-map works!</p> -->
<app-header></app-header>
<div style="margin-top: 120px;"></div>
<div class="container-fluid">
    <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Search for SJ branch..."
          [(ngModel)]="searchQuery"
          (keyup)="onKeyup()"
          style="text-transform: uppercase;"
        />&nbsp;&nbsp;
        <div class="input-group-append">
          <button class="btn btn-success" type="button" (click)="onSearch()">Search</button>
        </div>
    </div>
      
    <ul *ngIf="filteredLocations.length > 0" class="list-group">
        <li *ngFor="let location of filteredLocations" class="list-group-item list-group-item-action" (click)="selectLocation(location)">
            {{ location.s_brncname }}
        </li>
        <br>
    </ul>
      
    <p-gmap #gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'660px'}" 
    (onMapClick)="handleMapClick($event)" (onOverlayClick)="handleOverlayClick($event)" (onMapReady)="onMapReady($event)"></p-gmap>
    <br>
    <br>
    <br>
</div>

<!-- <div id="map" style="height: 500px; width: 100%;"></div> -->
