import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { HttpsvcService } from 'src/app/services/httpsvc.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ MessageService ]
})
export class LoginComponent implements OnInit {

  @Input() public _blnLoggedIn: boolean;

  submitted = false;

  loginForm: FormGroup;

  loading = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder, 
    private sharedService: SharedService,
    private httpsvc: HttpsvcService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    const rem = this.sharedService.getReMe();
    if(rem) {
      this.router.navigate(['/dashboard'], { state: { role: localStorage.getItem("_role"), name: localStorage.getItem("_name") }});
    }
    else {
      this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
        rememberme: [true]
      });
    }
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.loading = true;
    this.submitted = true;

    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }

    var cred = {
      "email" : this.loginForm.controls['username'].value,
      "password" : this.loginForm.controls['password'].value,
    }

    this.httpsvc.getToken(cred).subscribe(async data => {
      if (data != null && data.body != null) {
        if(data.body.data.active == 'Y') {
          // sessionStorage.setItem("_token", data.body.data.token); 
          // sessionStorage.setItem("_role" , data.body.data.role);
          // sessionStorage.setItem("_name", data.body.data.name);
          // sessionStorage.setItem("_usrid", data.body.data.id);

          if(this.loginForm.controls['rememberme'].value == true) {
            localStorage.setItem("_token", data.body.data.token); 
            localStorage.setItem("_role" , data.body.data.role);
            localStorage.setItem("_name", data.body.data.name);
            localStorage.setItem("_usrid", data.body.data.id);
            localStorage.setItem("isLogin", "true");
            this.sharedService.setReMe(true);
          }
          else {
            sessionStorage.setItem("_token", data.body.data.token); 
            sessionStorage.setItem("_role" , data.body.data.role);
            sessionStorage.setItem("_name", data.body.data.name);
            sessionStorage.setItem("_usrid", data.body.data.id);
            sessionStorage.setItem("isLogin", "true");
            this.sharedService.setReMe(false);
          }

          //this.sharedService.setGlobalInput(true);
          //this.router.navigate(['/dashboard']);
          this.router.navigate(['/dashboard'], { state: { role: data.body.data.role, name: data.body.data.name }}).then(() => {
            window.location.reload();
          });
        }
        else {
          this.showMsgErrorValidation('Username is inactive. Please try another user.');
          this.loading = false;
          this.submitted = true;
          return;
        }
        
      }
      
    },
    async error => {
      if(error.status == 403) {
        this.showMsgErrorValidation('Invalid username and password');
      } 
      else {
        this.showMsgErrorValidation('Error in connection! Refused connection.')
      }
      this.loading = false;
    })
    
  
  }

  showMsgErrorValidation(msg: string) {
    this.messageService.add({key: 'tc', severity:'error', summary: 'Invalid', detail:msg}); 
  }

}
