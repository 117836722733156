import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpsvcService } from 'src/app/services/httpsvc.service';
import { SharedService } from 'src/app/services/shared.service';
import * as Highcharts from 'highcharts';
import { Router } from '@angular/router';

declare var google: any;

@Component({
  selector: 'app-branch-map',
  templateUrl: './branch-map.component.html',
  styleUrls: ['./branch-map.component.scss']
})
export class BranchMapComponent implements OnInit, OnDestroy {

  Highcharts: typeof Highcharts = Highcharts;
  monthlydtl_hc: Highcharts.Options;

  options: any;
  subscription: Subscription;

  overlays: any[];

  gmap: any;

  _x = 0;

  infoWindow: any;

  branchInfo: any[] = [];
  dtsalesDetl: any[] = [];

  branchachi: any[] = [];
  branchdlyns: any[] = [];

  _token: string;
  _hc: any;

  isLog: boolean;

  searchQuery: string = '';
  filteredLocations = [];
  
  constructor(
    private httpsvc: HttpsvcService,
    private sharedService: SharedService,
    private router: Router
  ) {

    if(localStorage.getItem("rememberme") == "true") {
      this._token = localStorage.getItem('_token');
    }
    else if(localStorage.getItem("rememberme") == "false") {
      this._token = sessionStorage.getItem('_token');
    }
    else {
      this._token = "";
    }
    //this._token = sessionStorage.getItem('_token');
    
   }
  
   checkexpiredToken(): boolean {
    let bln: boolean = true;

    if(this._token != "") {
      this.subscription = this.httpsvc.getTNY(this._token).subscribe(resp => {
        bln = true;
      }, err => {
        bln = false;
        //console.log(err);
      });
    }
    else {
      bln = false;
    }

    //console.log(bln);
    return bln;
  }
  
  getSalesDetl() {
    this.subscription = this.httpsvc.getDSDetl(this._token).subscribe(resp => {
      this.dtsalesDetl = resp.body;
    }, err => {
      console.log(err);
    });
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
      
  }

  ngOnInit(): void {
    let _loc: any[] = [];
    
    let blnsuccess = this.checkexpiredToken();

    //this.isLog = this.sharedService.getGlobalInput();

    // if(!this.sharedService.getGlobalInput()) {
    //   this.router.navigate(['/']).then(() => {
    //     window.location.reload();
    //   });
    // }

    if(blnsuccess) {

      const remLS = localStorage.getItem("rememberme");

      if(remLS == "false" ) {
        if(sessionStorage.getItem("isLogin") == "false") {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }
        else if(sessionStorage.getItem("isLogin") == "true") {
          this.getSalesDetl();

          this.options = {
              center: {lat: 16.03249, lng: 120.32982},
              zoom: 10
          };

          this.subscription = this.httpsvc.getBranchList(this._token).subscribe(resp => {
            this.branchInfo = resp.body;
            for(let i = 0; i < this.branchInfo.length; i++) {
              var position = new google.maps.LatLng(this.branchInfo[i].lat, this.branchInfo[i].lng);
              // console.log(position);
              let marker = new google.maps.Marker({
                position: position,
                title: this.branchInfo[i].s_brncname,
                icon: '../../../assets/loc-icon.png'
              });
              _loc.push(marker);
            }
          }, err => {
            if(err.status == 401) {
              this.router.navigate(['/']).then(() => {
                window.location.reload();
              });
            }
            console.log(err);
          });

          this.subscription = this.httpsvc.getMapBranchAchi(this._token).subscribe(resp => {
            this.branchachi = resp.body;
          }, err => {
            console.log(err);
          });

          this.subscription = this.httpsvc.getMapBranchDlyNS(this._token).subscribe(resp => {
            this.branchdlyns = resp.body;
          }, err => {
            console.log(err);
          })

          this.overlays = _loc;
        }
      }
      else if (remLS == "true") {
        this.getSalesDetl();

        this.options = {
            center: {lat: 16.03249, lng: 120.32982},
            zoom: 10
        };

        this.subscription = this.httpsvc.getBranchList(this._token).subscribe(resp => {
          this.branchInfo = resp.body;
          for(let i = 0; i < this.branchInfo.length; i++) {
            var position = new google.maps.LatLng(this.branchInfo[i].lat, this.branchInfo[i].lng);
            // console.log(position);
            let marker = new google.maps.Marker({
              position: position,
              title: this.branchInfo[i].s_brncname,
              icon: '../../../assets/loc-icon.png'
            });
            _loc.push(marker);
          }
        }, err => {
          if(err.status == 401) {
            this.router.navigate(['/']).then(() => {
              window.location.reload();
            });
          }
          console.log(err);
        });

        this.subscription = this.httpsvc.getMapBranchAchi(this._token).subscribe(resp => {
          this.branchachi = resp.body;
        }, err => {
          console.log(err);
        });

        this.subscription = this.httpsvc.getMapBranchDlyNS(this._token).subscribe(resp => {
          this.branchdlyns = resp.body;
        }, err => {
          console.log(err);
        })

        this.overlays = _loc;
      }
    }
    else {
      this.sharedService.remLocalStorage();
      this.sharedService.remSessionStorage();
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }

    // this.getSalesDetl();

      // this.options = {
      //     center: {lat: 16.03249, lng: 120.32982},
      //     zoom: 10
      // };

      // this.subscription = this.httpsvc.getBranchList(this._token).subscribe(resp => {
      //   this.branchInfo = resp.body;
      //   for(let i = 0; i < this.branchInfo.length; i++) {
      //     var position = new google.maps.LatLng(this.branchInfo[i].lat, this.branchInfo[i].lng);
      //     // console.log(position);
      //     let marker = new google.maps.Marker({
      //       position: position,
      //       title: this.branchInfo[i].s_brncname,
      //       icon: '../../../assets/loc-icon.png'
      //     });
      //     _loc.push(marker);
      //   }
      // }, err => {
      //   console.log(err);
      // });

      // this.subscription = this.httpsvc.getMapBranchAchi(this._token).subscribe(resp => {
      //   this.branchachi = resp.body;
      // }, err => {
      //   console.log(err);
      // });

      // this.subscription = this.httpsvc.getMapBranchDlyNS(this._token).subscribe(resp => {
      //   this.branchdlyns = resp.body;
      // }, err => {
      //   console.log(err);
      // })

      // this.overlays = _loc;

}

onKeyup() {
  const query = this.searchQuery.toLowerCase();
  this.filteredLocations = this.branchInfo.filter(loc => loc.s_brncname.toLowerCase().includes(query));
}

selectLocation(location) {
  this.searchQuery = location.s_brncname;
  this.filteredLocations = []; // Clear suggestions

  this.panToLocation(location);

}

onMapReady(event: any) {
  this.gmap = event.map;
}

panToLocation(location) {
  if (this.gmap) {
    const latLng = new google.maps.LatLng(location.lat, location.lng);
    this.gmap.panTo(latLng);  // Pan to the selected location
    this.gmap.setZoom(20);    // Set the zoom level (optional)
  }

}

onSearch() {
  
  var infoWindow = new google.maps.InfoWindow();

  let fltrovrl: any[] = [];

  fltrovrl = this.overlays.filter(x => x.title === this.searchQuery);

  let marker = fltrovrl[0];

  let dtflter: any[] = [];
  let dtfltersales: any[] = [];
  let ytdsales = 0;
  let tot_ytdsales = 0;
  let yragosales = 0;
  let tot_yragosales = 0;
  let growth = 0;


  let bname = marker.getTitle();
  let bcode = bname.substring(0,2);

  let isMarker = marker.getTitle != undefined;

  dtflter = this.branchInfo.filter(mo => mo.s_brncname === bname);
  dtfltersales = this.dtsalesDetl.filter(x => x.bname === bname);

  for(let x = 0; x < dtfltersales.length; x++) {
    ytdsales = dtfltersales[x].netsalescurr;
    yragosales = dtfltersales[x].netsalesprev;

    tot_ytdsales = tot_ytdsales + ytdsales;

    if(ytdsales > 0) {
      tot_yragosales = tot_yragosales + yragosales;
    }
    
  }

  growth = (tot_ytdsales - tot_yragosales) / tot_yragosales * 100;
  
  let _totytdsales = new Intl.NumberFormat('en-US').format(tot_ytdsales);
  let _totyragosales = new Intl.NumberFormat('en-US').format(tot_yragosales);


  const caretDirection = growth < 0 ? 'down' : 'up';
  const color = growth < 0 ? 'red' : 'green';
  const valueText = growth.toFixed(2); 

  let strgrowth = `<span style="color: ${color};"><i class="bi bi-caret-${caretDirection}-fill"></i>
    ${valueText}%</span>`

    let chrtNS = `<div id="chart-container-${bname}" style="width: 250px; height: 140px;"></div>`;
    let chrtGrowth = `<div id="chart-cont-growth-${bname}" style="width: 250px; height: 140px;"></div>`;
    let chrtAchi = `<div id="chart-cont-achi-${bname}" style="width: 250px; height: 140px;"></div>`;
    let chrtDlyNS = `<div id="chart-cont-dlyns-${bname}" style="width: 250px; height: 140px;"></div>`;

  let tabs = 
  `<nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-ns-tab-${bcode}" data-bs-toggle="tab" data-bs-target="#nav-ns-${bcode}" type="button" role="tab" aria-controls="nav-ns-${bcode}" aria-selected="true">Net Sales</button>
      <button class="nav-link" id="nav-growth-tab-${bcode}" data-bs-toggle="tab" data-bs-target="#nav-growth-${bcode}" type="button" role="tab" aria-controls="nav-growth-${bcode}" aria-selected="false">Growth</button>
      <button class="nav-link" id="nav-achi-tab-${bcode}" data-bs-toggle="tab" data-bs-target="#nav-achi-${bcode}" type="button" role="tab" aria-controls="nav-achi-${bcode}" aria-selected="false">Achievement</button>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active" id="nav-ns-${bcode}" role="tabpanel" aria-labelledby="nav-ns-tab" tabindex="0">
    ${chrtNS}
    </div>
    <div class="tab-pane fade" id="nav-growth-${bcode}" role="tabpanel" aria-labelledby="nav-growth-tab" tabindex="1">${chrtGrowth}</div>
    <div class="tab-pane fade" id="nav-achi-${bcode}" role="tabpanel" aria-labelledby="nav-achi-tab" tabindex="2">${chrtAchi}</div>
  </div>`;

  let accord = 
    `<p class="d-inline-flex gap-1">
    <a style="font-size:12px" class="btn btn-light" data-bs-toggle="collapse" href="#collapse-${bcode}" role="button" aria-expanded="false" aria-controls="collapse-${bcode}">
      Daily Net Sales
    </a>
  </p>
  <div class="collapse" id="collapse-${bcode}">
    <div class="card card-body">
    <span style="font-size: 12px;"><i>Scrollable and Zoom Chart</i></span>
      ${chrtDlyNS}
      <br>
    </div>
  </div>`;

  if (isMarker) {
    let title = marker.getTitle();
    let pos = marker.getPosition();
    infoWindow.setContent('<b><u>' + title + '</u></b>' + '<br><br>' + 'Date Operate: ' + 
      dtflter[0].oprdate + '<br>' + 'Age: ' + dtflter[0].yrsage + ' yrs <br><br>' +
      '<table>' + '<tr><td>YTD Net Sales:</td><td>&#8369; ' + _totytdsales + '</td></tr>' +
      '<tr><td>YR-AGO Net Sales:</td><td>&#8369; ' + _totyragosales + '</td></tr>' +
      '<tr><td>Growth:</td><td>' + strgrowth + '</td></tr>' +
      '</table>' +
      '<br>' + tabs + '<br>' + accord);
    infoWindow.open(marker.map, marker);

    setTimeout(() => {
      this.createCh(bname);
    }, 1000);

    this.searchQuery = '';
  }
  else {
      // this.messageService.add({severity:'info', summary:'Shape Selected', detail: ''});
  }
}

handleMapClick(event) {
    //event: MouseEvent of Google Maps api
}

handleOverlayClick(event: any) {
  var infoWindow = new google.maps.InfoWindow();
  this.searchQuery = "";

  let dtflter: any[] = [];
  let dtfltersales: any[] = [];
  let ytdsales = 0;
  let tot_ytdsales = 0;
  let yragosales = 0;
  let tot_yragosales = 0;
  let growth = 0;

  let bname = event.overlay.getTitle();
  let bcode = bname.substring(0,2);

  let isMarker = event.overlay.getTitle != undefined;

  dtflter = this.branchInfo.filter(mo => mo.s_brncname === bname);
  dtfltersales = this.dtsalesDetl.filter(x => x.bname === bname);

  for(let x = 0; x < dtfltersales.length; x++) {
    ytdsales = dtfltersales[x].netsalescurr;
    yragosales = dtfltersales[x].netsalesprev;

    tot_ytdsales = tot_ytdsales + ytdsales;

    if(ytdsales > 0) {
      tot_yragosales = tot_yragosales + yragosales;
    }
    
  }

  growth = (tot_ytdsales - tot_yragosales) / tot_yragosales * 100;
  
  let _totytdsales = new Intl.NumberFormat('en-US').format(tot_ytdsales);
  let _totyragosales = new Intl.NumberFormat('en-US').format(tot_yragosales);


  const caretDirection = growth < 0 ? 'down' : 'up';
  const color = growth < 0 ? 'red' : 'green';
  const valueText = growth.toFixed(2); 

  let strgrowth = `<span style="color: ${color};"><i class="bi bi-caret-${caretDirection}-fill"></i>
    ${valueText}%</span>`

    let chrtNS = `<div id="chart-container-${bname}" style="width: 250px; height: 140px;"></div>`;
    let chrtGrowth = `<div id="chart-cont-growth-${bname}" style="width: 250px; height: 140px;"></div>`;
    let chrtAchi = `<div id="chart-cont-achi-${bname}" style="width: 250px; height: 140px;"></div>`;
    let chrtDlyNS = `<div id="chart-cont-dlyns-${bname}" style="width: 250px; height: 140px;"></div>`;

  let tabs = 
  `<nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-ns-tab-${bcode}" data-bs-toggle="tab" data-bs-target="#nav-ns-${bcode}" type="button" role="tab" aria-controls="nav-ns-${bcode}" aria-selected="true">ADS</button>
      <button class="nav-link" id="nav-growth-tab-${bcode}" data-bs-toggle="tab" data-bs-target="#nav-growth-${bcode}" type="button" role="tab" aria-controls="nav-growth-${bcode}" aria-selected="false">Growth</button>
      <button class="nav-link" id="nav-achi-tab-${bcode}" data-bs-toggle="tab" data-bs-target="#nav-achi-${bcode}" type="button" role="tab" aria-controls="nav-achi-${bcode}" aria-selected="false">Achievement</button>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active" id="nav-ns-${bcode}" role="tabpanel" aria-labelledby="nav-ns-tab" tabindex="0">
    ${chrtNS}
    </div>
    <div class="tab-pane fade" id="nav-growth-${bcode}" role="tabpanel" aria-labelledby="nav-growth-tab" tabindex="1">${chrtGrowth}</div>
    <div class="tab-pane fade" id="nav-achi-${bcode}" role="tabpanel" aria-labelledby="nav-achi-tab" tabindex="2">${chrtAchi}</div>
  </div>`;

  let accord = 
    `<p class="d-inline-flex gap-1">
    <a style="font-size:12px" class="btn btn-light" data-bs-toggle="collapse" href="#collapse-${bcode}" role="button" aria-expanded="false" aria-controls="collapse-${bcode}">
      Daily Net Sales
    </a>
  </p>
  <div class="collapse" id="collapse-${bcode}">
    <div class="card card-body">
    <span style="font-size: 12px;"><i>Scrollable and Zoom Chart</i></span>
      ${chrtDlyNS}
      <br>
    </div>
  </div>`;

  


  if (isMarker) {
    let title = event.overlay.getTitle();
    let pos = event.overlay.getPosition();
    infoWindow.setContent('<b><u>' + title + '</u></b>' + '<br><br>' + 'Date Operate: ' + 
      dtflter[0].oprdate + '<br>' + 'Age: ' + dtflter[0].yrsage + ' yrs <br><br>' +
      '<table>' + '<tr><td>YTD Net Sales:</td><td>&#8369; ' + _totytdsales + '</td></tr>' +
      '<tr><td>YR-AGO Net Sales:</td><td>&#8369; ' + _totyragosales + '</td></tr>' +
      '<tr><td>Growth:</td><td>' + strgrowth + '</td></tr>' +
      '</table>' +
      '<br>' + tabs + '<br>' + accord);
    infoWindow.open(event.map, event.overlay);
    event.map.setCenter(event.overlay.getPosition());

    setTimeout(() => {
      this.createCh(bname);
    }, 1000);
  }
  else {
      // this.messageService.add({severity:'info', summary:'Shape Selected', detail: ''});
  }

 
}

createCh(name: string) {
  let dts: any[] = [];
  let dtachi: any[] = [];
  let dtdns: any[] = [];

  let cat: any[] = [];
  let valcurr: any[] = [];
  let valprev: any[] = [];
  let valcurrads: any[] = [];
  let valprevads: any[] = [];
  let valgrowth: any[] = [];

  let achi_cat: any[] = [];
  let achi_val: any[] = [];
  let achi_ads: any[] = [];
  let achi_quota: any[] = [];

  let dns_cat: any[] = [];
  let dns_val: any[] = [];

  dts = this.dtsalesDetl.filter(x => x.bname === name);
  dtachi = this.branchachi.filter(x=> x.bname === name);
  dtdns = this.branchdlyns.filter(x=> x.bname === name);

  // Netsales and Growth Chart
  for(let x = 0; x < dts.length; x++) {
    cat.push(dts[x].moname);
    valcurr.push(Number(dts[x].netsalescurr));
    valprev.push(Number(dts[x].netsalesprev));
    valcurrads.push(Number(dts[x].currADS));
    valprevads.push(Number(dts[x].prevADS));
    valgrowth.push(Number(dts[x].growth));
  }

  for(let j = 0; j < dtachi.length; j++) {
    achi_cat.push(dtachi[j].moname);
    achi_val.push(Number(dtachi[j].salesachi));
    achi_ads.push(Number(dtachi[j].avgsales));
    achi_quota.push(Number(dtachi[j].quota));
  }

  for(let i = 0; i < dtdns.length; i++) {
    dns_cat.push(dtdns[i].dtday);
    dns_val.push(Number(dtdns[i].netsales));
  }


  Highcharts.chart('chart-container-'+ name, {
    chart: {
      type: 'column'
    },
    legend: { 
      enabled:false 
    },
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    xAxis: {
      categories: cat,
      gridLineWidth: 0
    },
    yAxis: {
      labels: {
        enabled: false
        },
      gridLineWidth: 0,
      style: {
        display: 'none'
      },
      title: {
        text: null
      }
    },
    series: [
      {
        type: 'column',
        data: valcurrads,
        style: {
          display: 'none'
        }
      },
      {
        type: 'line',
        data: valprevads,
        style: {
          display: 'none'
        },
        marker: {
          enabled: false,
          states: {
            hover: {
                enabled: false
            }
          }
        }
      }
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        const s1 = this.points[0].y;
        const s2 = this.points[1].y;
        const xdiff = s1 - s2;
        const gr = xdiff / s2 * 100;

        const caretDirection = xdiff < 0 ? 'down' : 'up';
        const color = xdiff < 0 ? 'red' : 'green';
        const valueText = xdiff.toFixed(2); // Format the value

        let strgrowthx = `<span style="color: ${color};"><i class="bi bi-caret-${caretDirection}-fill"></i>
        ₱ ${Highcharts.numberFormat(valueText, 2, '.', ',')}</span>`

        // return `${this.x}: ₱ ${Highcharts.numberFormat(this.y, 2, '.', ',')}`;
        
        return `<b>${this.x}</b><br/>
                <span><i class="bi bi-circle-fill" style="color: ${this.points[0].color}"></i> ADS : ₱ ${Highcharts.numberFormat(s1, 2, '.', ',')}</span><br>
                <span><i class="bi bi-circle-fill" style="color: ${this.points[1].color}"></i> YRAGO ADS : ₱ ${Highcharts.numberFormat(s2, 2, '.', ',')}</span><br>`
                //${strgrowthx}
                // <span>Diff: ₱ ${Highcharts.numberFormat(xdiff, 2, '.', ',')}</span>
      }
    }
  });

  Highcharts.chart('chart-cont-growth-' + name, {
    chart: {
      type: 'column'
    },
    legend: { 
      enabled:false 
    },
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    xAxis: {
      categories: cat,
      gridLineWidth: 0
    },
    yAxis: {
      labels: {
        enabled: false
        },
      gridLineWidth: 0,
      style: {
        display: 'none'
      },
      title: {
        text: null
      }
    },
    series: [
      {
        type: 'column',
        data: valgrowth,
        style: {
          display: 'none'
        },
        zones: [
          {
            value: 0, 
            color: 'red'
          }, {
            color: 'green' 
          }
        ]
      }
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        const s1 = this.points[0].y;

        const caretDirection = s1 < 0 ? 'down' : 'up';
        const color = s1 < 0 ? 'red' : 'green';
        const valueText = s1.toFixed(2); // Format the value

        let strgrowthx = `<span style="color: ${color};"><i class="bi bi-caret-${caretDirection}-fill"></i>
        ${valueText}%</span>`

        // return `${this.x}: ${Highcharts.numberFormat(this.y, 2, '.', ',')}%`;
        
        return `<b>${this.x}</b><br/>
                ${strgrowthx}`
      }
    }
  });

  Highcharts.chart('chart-cont-achi-' + name, {
    chart: {
      type: 'column'
    },
    legend: { 
      enabled:false 
    },
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    xAxis: {
      categories: achi_cat,
      gridLineWidth: 0
    },
    yAxis: {
      labels: {
        enabled: false
        },
      gridLineWidth: 0,
      style: {
        display: 'none'
      },
      title: {
        text: null
      }
    },
    series: [
      {
        type: 'area',
        data: achi_quota,
        color: '#f6e08a',
        style: {
          display: 'none'
        },
        marker: {
          enabled: false,
          states: {
            hover: {
                enabled: false
            }
          }
        }
      },
      {
        type: 'column',
        data: achi_ads,
        style: {
          display: 'none'
        }
      },
      
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        const s1 = this.points[0].y;
        const s2 = this.points[1].y;
        const gr = s2 / s1 * 100;

        // const caretDirection = gr < 0 ? 'down' : 'up';
        // const color = gr < 0 ? 'red' : 'green';
        const valueText = gr.toFixed(2); // Format the value

        //let strgrowthx = `<span style="color: ${color};"><i class="bi bi-caret-${caretDirection}-fill"></i>${valueText}%</span>`
        let strgrowthx = `<span>ACHIEVEMENT: ${valueText}%</span>`
        // return `${this.x}<br>${Highcharts.numberFormat(this.y, 2, '.', ',')}%`;
        
        return `<b>${this.x}</b><br/>
                <span><i class="bi bi-circle-fill" style="color: ${this.points[0].color}"></i> QUOTA : ₱ ${Highcharts.numberFormat(s1, 2, '.', ',')}</span><br>
                <span><i class="bi bi-circle-fill" style="color: ${this.points[1].color}"></i> ADS : ₱ ${Highcharts.numberFormat(s2, 2, '.', ',')}</span><br>
                ${strgrowthx}`
                // <span>${Highcharts.numberFormat(strgrowthx, 2, '.', ',')}%</span>
      }
    }
  });

  Highcharts.chart('chart-cont-dlyns-' + name, {
    chart: {
      type: 'spline',
      scrollablePlotArea: {
        minWidth: 700,
        scrollPositionX: 1
      },
      zoomType: 'x',
        panning: true,
        panKey: 'shift'
    },
    legend: { 
      enabled:false 
    },
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    xAxis: {
      categories: dns_cat,
      gridLineWidth: 0,
      labels: {
        overflow: 'justify'
      }
    },
    yAxis: {
      labels: {
        enabled: false
        },
      gridLineWidth: 0,
      style: {
        display: 'none'
      },
      title: {
        text: null
      }
    },
    series: [
      {
        type: 'spline',
        data: dns_val,
        // color: '#f6e08a',
        style: {
          display: 'none'
        },
        marker: {
          enabled: false,
          states: {
            hover: {
                // enabled: false
                lineWidth: 5
            }
          }
        }
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        const s1 = this.points[0].y;
        // const s2 = this.points[1].y;
        // const gr = s2 / s1 * 100;

        // const caretDirection = gr < 0 ? 'down' : 'up';
        // const color = gr < 0 ? 'red' : 'green';
        // const valueText = gr.toFixed(2); // Format the value

        // let strgrowthx = `<span style="color: ${color};"><i class="bi bi-caret-${caretDirection}-fill"></i>
        // ${valueText}%</span>`

        return `${this.x}<br>₱ ${Highcharts.numberFormat(this.y, 2, '.', ',')}`;
        
        // return `<b>${this.x}</b><br/>
        //         <span><i class="bi bi-circle-fill" style="color: ${this.points[0].color}"></i> QUOTA : ₱ ${Highcharts.numberFormat(s1, 2, '.', ',')}</span><br>
        //         <span><i class="bi bi-circle-fill" style="color: ${this.points[1].color}"></i> ADS : ₱ ${Highcharts.numberFormat(s2, 2, '.', ',')}</span><br>
        //         ${strgrowthx}`
                // <span>${Highcharts.numberFormat(strgrowthx, 2, '.', ',')}%</span>
      }
    }
  });

}

}
